import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { fetchMaterials } from "../../../../redux/action";
import { useEffect, useState } from "react";

const MaterialSelector = ({ setFormData, value, error }) => {
  const dispatch = useDispatch();
  const materials = useSelector((state) => state.materials.materials);
  const [selectedMaterial, setSelectedMaterial] = useState(null);

  const sortedMaterials = [...materials].sort((a, b) => {
    const aNumber = a.MG ? a.MG.split(" ")[0] : "";
    const bNumber = b.MG ? b.MG.split(" ")[0] : "";
    return parseInt(aNumber) - parseInt(bNumber);
  });

  useEffect(() => {
    if (value) {
      const defaultValue = {
        value: value,
        label: value,
      };

      setSelectedMaterial(defaultValue);
    } else {
      setSelectedMaterial("");
    }
  }, [value]);

  useEffect(() => {
    dispatch(fetchMaterials());
  }, [dispatch]);

  const options = sortedMaterials.map((material) => ({
    value: material.MG,
    label: material.MG,
  }));

  const handleMaterialGroupChange = (selectedOption) => {
    setSelectedMaterial(selectedOption);
    setFormData((prevFormData) => ({
      ...prevFormData,
      materialgrp: selectedOption ? selectedOption.value : "",
    }));
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: "#E5E7EB",
    }),
  };

  return (
    <div>
      <label
        htmlFor="materialgrp"
        className="block text-sm font-medium text-gray-900 uppercase mb-1"
      >
        Material Group
      </label>
      <Select
        value={selectedMaterial}
        onChange={handleMaterialGroupChange}
        isClearable
        isSearchable
        isMulti={false}
        options={options}
        styles={customStyles}
        className={`border-gray-400 ${
          error && "border-red-600"
        } bg-gray-200 border-2 rounded-md text-sm`}
        placeholder="Select material group..."
      />
    </div>
  );
};

export default MaterialSelector;
