import React, { useState } from "react";
import { FaDownload } from "react-icons/fa6";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { exportPrData, exportSIData } from "../../../redux/action";
import Loader from "../../UI/Loader";
import { exportToExcel } from "../../utils/exportToExcel";
import toast from "react-hot-toast";
const Modal = ({ isOpen, onClose, children, isScroll }) => {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center w-screen h-screen bg-gray-900 bg-opacity-50 py-10">
      <div
        className={`relative z-50 p-8 bg-white rounded-md shadow-lg xl:w-[30rem] min-w-[30rem] min-h-[14rem] ${
          isScroll && "overflow-y-scroll"
        }`}
      >
        {children}
        <button
          className="absolute top-0 right-0 mt-6 mr-6 text-xl text-gray-500 hover:text-gray-700 transition-colors duration-300"
          onClick={onClose}
        >
          <IoCloseSharp />
        </button>
      </div>
    </div>
  );
};

const ViewExport = ({ isOpen, setOpen, type }) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === "from") {
      setFromDate(value);
    } else if (name === "to") {
      setToDate(value);
    }
  };
  const handleOnClick = async () => {
    setLoading(true);

    const action = type === "PR" ? exportPrData : exportSIData;

    await dispatch(action({ fromDate, toDate }))
      .then((res) => {
        exportToExcel(res?.data, `${type}-Export`);
        toast.success("Export successful!");
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Export failed!");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Modal isOpen={isOpen} setOpen={setOpen} onClose={() => setOpen(false)}>
      {loading ? (
        <div className=" flex items-center justify-center h-full min-h-[12rem] bg-opacity-50">
          <Loader />
        </div>
      ) : (
        <>
          <h1 className="font-medium text-lg mb-1">Export</h1>
          <div className="flex">
            <p className="text-xs font-light text-red-500 text-center mb-6 italic">
              (* Keep the date field empty to download all)
            </p>
          </div>
          <div className="flex flex-row sm:flex-col items-center justify-center space-y-6 sm:space-y-4 sm:space-x-12">
            <div className="flex flex-row sm:flex-row items-center justify-center space-y-2 sm:space-y-0 sm:space-x-6">
              <div>
                <label className="text-[12px] block mb-1">From</label>
                <input
                  name="from"
                  type="date"
                  value={fromDate}
                  onChange={handleDateChange}
                  className="bg-gray-200 border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-6 py-1.5"
                />
              </div>

              <div>
                <label className="text-[12px] block mb-1">To</label>
                <input
                  name="to"
                  type="date"
                  value={toDate}
                  onChange={handleDateChange}
                  className="bg-gray-200 border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-6 py-1.5"
                />
              </div>
            </div>

            <div className="flex w-full h-full px-32">
              <button
                onClick={handleOnClick}
                className="bg-[#1e3a8a] flex items-center justify-center text-white text-[14px] rounded-md px-4 py-2 hover:bg-[#1a3078] transition-colors duration-300"
              >
                <p className="mr-2">Download</p> <FaDownload />
              </button>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ViewExport;
