import React, { useState } from "react";
import Modal from "../Modal";
import AppInput from "../Input/AppTextInput";
import { toast } from "react-hot-toast";

const UserActionModal = ({ isOpen, setOpen, isNew, user, setTriggerLoad }) => {
  const [formData, setFormData] = useState({
    username: user?.username || "",
    email: user?.email || "",
    password: "",
    role: user?.role || "",
  });

  const [loading, setLoading] = useState(false);

  function handleChangeInput(e) {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  }

  async function handleCreateUser() {
    const token = localStorage.getItem("jwttoken");

    if (isNew) {
      if (
        !formData.username ||
        !formData.email ||
        !formData.role ||
        !formData.password
      ) {
        toast.error("Please Fill all fields");
        return;
      }

      if (formData.role === "select") {
        toast.error("Please select role");
        return;
      }

      if (validation()) {
        return;
      }

      setLoading(true);

      const API = `${process.env.REACT_APP_SERVER_URL}/register`;

      const data = {
        ...formData,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(data),
      };

      try {
        const res = await fetch(API, requestOptions);

        const json = await res.json();

        if (res.ok) {
          toast.success("New User Created");
          setTriggerLoad((prv) => !prv);
          setOpen(false);
        } else {
          toast.error(json.error);
        }
      } catch (err) {
        console.log(err);
        toast.error("Something goes wrong");
      }

      setLoading(false);
    } else {
      if (!formData.username || !formData.email || !formData.role) {
        toast.error("Please Fill all fields");
        return;
      }

      if (validation()) {
        return;
      }

      setLoading(true);

      const API = `${process.env.REACT_APP_SERVER_URL}/editUser`;

      const data = {
        ...formData,
        _id: user._id,
      };

      const requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(data),
      };

      try {
        const res = await fetch(API, requestOptions);
        const json = await res.json();

        if (res.ok) {
          toast.success("User Updated Successfully");
          setTriggerLoad((prv) => !prv);
          setOpen(false);
        } else {
          toast.error(json.error);
        }
      } catch (err) {
        console.log(err);
        toast.error("Something goes wrong");
      }

      setLoading(false);
    }
  }

  function validation() {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(formData.email)) {
      toast.error("Please enter valid email");
      return true;
    }

    return false;
  }

  return (
    <Modal isOpen={isOpen} onClose={() => setOpen(false)}>
      <div className="flex flex-col items-center">
        <h3 className="font-medium text-xl">
          {isNew ? "Create New User" : "Edit User"}{" "}
        </h3>

        <div className="mt-5 flex flex-col gap-y-3">
          <AppInput
            width={"w-96"}
            type={"text"}
            value={formData.username}
            label={"Full Name"}
            onChange={handleChangeInput}
            name={"username"}
          />
          <AppInput
            type={"email"}
            value={formData.email}
            label={"Email"}
            onChange={handleChangeInput}
            name={"email"}
          />

          <AppInput
            type={"text"}
            value={formData.password}
            label={"Password"}
            onChange={handleChangeInput}
            name={"password"}
          />

          <div>
            <label className="block text-sm font-medium text-gray-900">
              Role
            </label>
            <select
              name="role"
              value={formData.role}
              onChange={(e) => handleChangeInput(e)}
              className="mt-1 bg-gray-200  rounded-md border-gray-400 border px-2 py-2  w-full "
            >
              <option>select</option>
              <option value={"user"}>User</option>
              <option value={"approver"}>Approver</option>
              <option value={"admin"}>Admin</option>
            </select>
          </div>

          <div className="flex justify-center">
            <button
              onClick={handleCreateUser}
              className="bg-green-600 px-5 py-2  w-full mt-8 mb-10 rounded-md text-white"
            >
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UserActionModal;
