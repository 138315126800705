// MyContext.js
import React, { createContext, useContext, useState } from "react";

// Create the context
const ApproverContext = createContext();

// Create a custom hook to access the context
export const useMyContext = () => useContext(ApproverContext);

// Create a provider component to wrap your app
export const MyContextProvider = ({ children }) => {
  const [toreview, setToreview] = useState(false);
  const [toview, setView] = useState(false);
  const [toreviewSi, setToreviewSi] = useState(false);
  const [prnumber, setPrnumber] = useState("");

  return (
    <ApproverContext.Provider
      value={{
        toview,
        setView,
        toreview,
        setToreview,
        toreviewSi,
        setToreviewSi,
        prnumber,
        setPrnumber,
      }}
    >
      {children}
    </ApproverContext.Provider>
  );
};
