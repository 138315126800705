import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";

const ApproverActionSection = ({ approverData, prnumber }) => {
  const navigate = useNavigate();

  const email = useSelector((state) => state.auth.email);
  const pending1 =
    approverData?.length > 0 && approverData[0]?.status === "Pending"
      ? true
      : false;
  const pending2 =
    approverData?.length > 1 && approverData[1]?.status === "Pending"
      ? true
      : false;
  const pending3 =
    approverData?.length > 2 && approverData[2]?.status === "Pending"
      ? true
      : false;
  const pending4 =
    approverData?.length > 3 && approverData[3]?.status === "Pending"
      ? true
      : false;

  const pendingList = [pending1, pending2, pending3, pending4];

  const [comment, setComment] = useState("");

  const handleApproveReject = async (status) => {
    try {
      const data = {
        comments: comment,
        status,
      };

      const authToken = localStorage.getItem("jwttoken");

      const requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken}`,
        },
        body: JSON.stringify(data),
      };

      const res = await fetch(
        process.env.REACT_APP_SERVER_URL +
          `/purchase-request/review/${prnumber}`,
        requestOptions
      );

      if (res.ok) {
        toast.success("Approver status updated");
        navigate("/dashboard/pr-approvals");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    approverData?.length > 0 && (
      <div className="mt-10">
        <h1 className="font-bold">For Approvers</h1>

        <div className="border border-gray-300 flex flex-col gap-y-2 rounded-md w-full p-5 mt-1 ">
          {approverData?.map((item, i) => {
            if (i === 1 && pending1) {
              return (
                <div className="mt-5">
                  <div className="flex gap-x-5 ">
                    <p>Approver {i + 1}:</p>
                  </div>
                  <>
                    <p className="ml-5 mt-2 text-[15px]">Name : {item?.name}</p>
                    <p className="ml-5 mt-2 text-[15px]">
                      Email : {item?.email}
                    </p>
                    <p className="text-green-600 ml-5 mt-2 text-[15px]">
                      <span className="text-black">Status</span> :{" "}
                      {item?.status}
                    </p>
                  </>
                </div>
              );
            }

            if (i === 2 && pending2) {
              return (
                <div className="mt-5">
                  <div className="flex gap-x-5 ">
                    <p>Approver {i + 1}:</p>
                  </div>
                  <>
                    <p className="ml-5 mt-2 text-[15px]">Name : {item?.name}</p>
                    <p className="ml-5 mt-2 text-[15px]">
                      Email : {item?.email}
                    </p>
                    <p className="text-green-600 ml-5 mt-2 text-[15px]">
                      <span className="text-black">Status</span> :{" "}
                      {item?.status}
                    </p>
                  </>
                </div>
              );
            }

            if (i === 3 && pending3) {
              return (
                <div className="mt-5">
                  <div className="flex gap-x-5 ">
                    <p>Approver {i + 1}:</p>
                  </div>
                  <>
                    <p className="ml-5 mt-2 text-[15px]">Name : {item?.name}</p>
                    <p className="ml-5 mt-2 text-[15px]">
                      Email : {item?.email}
                    </p>
                    <p className="text-green-600 ml-5 mt-2 text-[15px]">
                      <span className="text-black">Status</span> :{" "}
                      {item?.status}
                    </p>
                  </>
                </div>
              );
            }

            const status1 =
              item?.status === "Approved"
                ? true
                : item.status === "Rejected"
                ? true
                : false;

            return (
              <div>
                <div className="flex gap-x-5">
                  <p>Approver {i + 1}:</p>
                </div>

                {status1 && !pendingList[i] ? (
                  <>
                    <p className="ml-5 mt-2 text-[15px]">Name : {item?.name}</p>
                    <p className="ml-5 mt-2 text-[15px]">
                      Email : {item?.email}
                    </p>
                    <p className="text-green-600 ml-5 mt-2 text-[15px]">
                      <span className="text-black">Status</span> :{" "}
                      {item?.status}
                    </p>
                    <p className="ml-5 mt-2 text-[15px] mb-2">
                      Comments : {item?.comments}
                    </p>
                  </>
                ) : item?.email === email ? (
                  <div>
                    <div>
                      <p className="ml-5 mt-2 text-[15px]">
                        Name : {item?.name}
                      </p>
                      <p className="ml-5 mt-2 text-[15px]">
                        Email : {item?.email}
                      </p>
                      <p className="text-green-600 ml-5 mt-2 text-[15px]">
                        <span className="text-black">Status</span> :{" "}
                        {item?.status}
                      </p>
                    </div>
                    <p className="text-[14px] mt-3">Approver's Comments:</p>
                    <textarea
                      onChange={(e) => setComment(e.target.value)}
                      value={comment}
                      className="mt-2  bg-gray-200 border w-full px-3 py-2 border-gray-400 text-gray-900  rounded-lg "
                    />
                    <div className="flex gap-x-3 text-[14px] mt-2">
                      <button
                        onClick={() => {
                          handleApproveReject("Approved");
                        }}
                        className="bg-green-600 px-4 py-2 text-white rounded-md"
                      >
                        Approve
                      </button>
                      <button
                        onClick={() => {
                          handleApproveReject("Rejected");
                        }}
                        className="bg-red-600 px-4 py-2 text-white rounded-md"
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                ) : (
                  <NavigateToPage />
                )}
              </div>
            );
          })}
        </div>
      </div>
    )
  );
};

function NavigateToPage() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/dashboard/pr-dashboard");
  }, []);
  return <></>;
}

export default ApproverActionSection;
