import React, { useEffect, useState } from "react";

const AppMultipleEmailInput = ({
  onChange,
  name,
  label,
  value,
  disable,
  isError,
  width,
}) => {
  const [emails, setEmails] = useState(value || "");
  const [error, setError] = useState(isError || false);

  const validateEmails = (emailString) => {
    if (!emailString) return true;
    const emailArray = emailString.split(",").map((email) => email.trim());
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailArray.every((email) => emailRegex.test(email));
    return isValid;
  };
  useEffect(() => {
    setEmails(value || "");
  }, [value]);
  const handleChange = (e) => {
    const { value } = e.target;
    setEmails(value);
    const valid = validateEmails(value);
    setError(!valid);
    if (onChange) {
      onChange(e, valid);
    }
  };

  return (
    <div>
      <label className="block text-sm font-medium text-gray-900" htmlFor={name}>
        {label}
      </label>
      <input
        disabled={disable}
        value={emails}
        className={`bg-gray-200 ${
          error && "border-red-400 border-2"
        } border mt-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block ${
          width ? width : "w-full"
        }  p-2.5`}
        type="text"
        name={name}
        onChange={handleChange}
      />
      {error && (
        <p className="text-red-500 text-sm mt-1">Invalid email format</p>
      )}
    </div>
  );
};

export default AppMultipleEmailInput;
