import React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  // GridToolbarExport,
} from "@mui/x-data-grid";
import { useMediaQuery } from "react-responsive";

function CustomToolbar() {
  return (
    <GridToolbarContainer>{/* <GridToolbarExport /> */}</GridToolbarContainer>
  );
}

const MyDataGrid = ({ columns, rows, bgColor, idKey = "id" }) => {
  const isBigScreen = useMediaQuery({ query: "(min-width: 1324px)" });

  return (
    <div className="h-5/6 overflow-hidden">
      <DataGrid
        sx={{
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
          ".MuiDataGrid-columnHeadersInner": {
            background: bgColor,
            width: isBigScreen ? "100%" : "auto",
          },
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: "700",
            color: "white",
          },
          ".MuiDataGrid-toolbarContainer": {
            justifyContent: "end",
          },
          ".MuiDataGrid-footerContainer": {
            display: "none",
          },
          ".MuiDataGrid-root": {
            border: "none",
            display: "none",
          },
          ".MuiDataGrid-row": {
            "&.Mui-selected": {
              backgroundColor: "#f5f5f5", // Customize selected row background
            },
          },
          ".MuiDataGrid-overlay": {
            display: "none",
          },
        }}
        getRowId={(row) => row[idKey]}
        rows={rows}
        columns={columns}
        slots={{
          toolbar: CustomToolbar,
        }}
      />
      {rows?.length === 0 && (
        <p className="text-center mt-10 font-semibold">No Records</p>
      )}
    </div>
  );
};

export default MyDataGrid;
