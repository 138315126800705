import React from "react";

const Loader = () => {
  return (
    <div className="flex items-center justify-center h-full ">
      <div class="border-gray-200 h-10 w-10 animate-spin rounded-full border-4 border-t-blue-900" />
    </div>
  );
};

export default Loader;
