import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getpendingSiById } from "../../../../redux/action";
import SIFormDynamicValues from "../../si/form/SIFormDynamicValue";
// import SIApproverSelector from "./SIApproverSelector";
import AppTextArea from "../../../UI/Input/AppTextArea";
import Modal from "../../../UI/Modal";
import AppTextInput from "../../../UI/Input/AppTextInput";
import { BsCheckCircle } from "react-icons/bs";
import AppSelectInput from "../../../UI/Input/AppSelectInput";
import ApproverActionSectionSi from "./approverActionSectionSi";
const SiFormReview = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const prnumber = searchParams.get("prnumber");
  const initialDetail = {
    prnumber: "",
    sino: "",
    sititle: "",
    siDate: "",
    projectnumber: "",
    companyname: "",
    descriptionOfWork: "",
    associatedWorks: "",
    contractScope: "",
    resonForInstruction: "",
    totalValue: "",
    totalValueCurrency: "",
    timeExtension: "",
    timeExtensionUnit: "",
    raisedByname:"",
    raisedByteam:"",
    raisedBydesignation:"",
  };

  const [formData, setFormData] = useState(initialDetail);
  // const [selectedApprovers, setSelectedApprovers] = useState([]);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  // const [modalContent, setModalContent] = useState({
  //   title: "",
  //   message: "",
  //   sinumber: "",
  // });

  

  useEffect(() => {
    dispatch(getpendingSiById(prnumber));
  }, [dispatch, prnumber]);
  const siRequest = useSelector((state) => state.singlePendingSi.siPendingRequests);
  console.log(siRequest)
  useEffect(() => {
    if (siRequest) {
      setFormData(siRequest);
    }
  }, [siRequest]);

  function handleSubmit(e) {
    e.preventDefault();
  }

  return (
    <section className="shadow-lg p-5 bg-white">
      <h1 className="text-2xl font-bold leading-6 text-slate-700 mb-8 text-center">
        Review Visy Glass Site Instruction Request Form
      </h1>

      <form
        onSubmit={handleSubmit}
        className="flex flex-col justify-center items-center gap-y-5 mt-3 w-full"
      >
        <div className="w-[50rem] flex flex-col gap-y-5">
          <SIFormDynamicValues
            siDate={formData.siDate}
            siNumber={siRequest?.sino}
            status={formData.sistatus}
          />

          <AppTextInput
            disable={true}
            name={"sititle"}
            label={"SI TITLE"}
            value={formData.sititle}
          />
          <AppTextInput
            disable={true}
            name={"projectnumber"}
            label={"PROJECT NO."}
            value={formData.projectnumber}
          />
          <AppTextInput
            disable={true}
            name={"companyname"}
            label={"TO COMPANY NAME"}
            value={formData.companyname}
          />
          <p>
            Please note: You are directed to execute the following Site
            Instruction promptly.
            <br /> This Site Instruction indicates approved time or cost
            variation.
          </p>
          <AppTextArea
            disable={true}
            isBold={true}
            name={"descriptionOfWork"}
            label={"DESCRIPTION OF WORKS:"}
            value={formData.descriptionOfWork}
            row={20}
          />

          <div>
            <p className="text-sm font-semibold text-gray-900">
              REASON FOR INSTRUCTION:
            </p>
            <div className="flex justify-between mt-2 gap-x-5 items-center">
              <div className="flex items-center gap-x-3">
                <p className="font-medium">Time Extension</p>
                <input
                  disable
                  placeholder="0"
                  className="bg-[#e2efda] font-semibold text-center placeholder:text-center  border border-gray-400 rounded-md px-2 py-1 w-20"
                  name="timeExtension"
                  value={formData.timeExtension || ""}
                />
                <select
                  disable
                  name="timeExtension"
                  className=" bg-[#e2efda] font-semibold rounded-md border-gray-400 border p-1 w-[80px]"
                  value={formData.timeExtensionUnit || ""}
                >
                  <option value="Hours">Hours</option>
                  <option value="Days">Days</option>
                </select>
              </div>
              <div className="flex items-center gap-x-3">
                <p className="font-medium">Total Value Site Instruction</p>
                <input
                  placeholder="0.00"
                  className="bg-[#e2efda] font-semibold text-right border border-gray-400 rounded-md px-2 py-1 w-32"
                  name="totalValueSiteInstruction"
                  value={formData.totalValue}
                />
                <select
                  disable
                  name="totalValueSiteInstruction"
                  className="bg-[#e2efda] font-semibold rounded-md border-gray-400 border p-1 w-[100px]"
                  value={formData.totalValueCurrency}
                >
                  <option value="AUD">AUD</option>
                  <option value="NZD">NZD</option>
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                  <option value="GBP">GBP</option>
                  <option value="CNY">CNY</option>
                  <option value="IDR">IDR</option>
                  <option value="VND">VND</option>
                  <option value="MYR">MYR</option>
                </select>
              </div>
            </div>
          </div>
          <AppTextArea
            disable={true}
            name={"associatedWorks"}
            label={"ASSOCIATED WORKS:"}
            row={10}
            isBold={true}
            value={formData.associatedWorks}
          />
          <AppSelectInput
            disable={true}
            isVertical={true}
            name={"contractScope"}
            getter={"val"}
            label={"Is work covered within current contract scope?"}
            data={[{ val: "In Scope" }, { val: "Out of Scope" }]}
            value={formData.contractScope}
          />
          <AppTextArea
            disable={true}
            name={"resonForInstruction"}
            label={"REASON FOR INSTRUCTION:"}
            row={10}
            isBold={true}
            value={formData.resonForInstruction}
          />
          <div>
            <p className="font-semibold text-gray-900">Raised By:</p>
            <div className="ml-10">
              <div className="flex gap-x-1 justify-between  mt-3">
                <div className="flex gap-x-5 items-center">
                  <label className="w-20 text-sm font-medium text-gray-900">
                    Name
                  </label>
                  <input
                    disable
                    name="raisedBy"
                    value={formData.raisedByname}
                    className="bg-gray-200  w-80 border mt-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5"
                  />
                </div>
                <div className="flex gap-x-5 items-center">
                  <label className=" text-sm font-medium text-gray-900">
                    of the Team
                  </label>
                  <input
                    disable
                    name="raisedBy"
                    value={formData.raisedByteam}
                    className="bg-gray-200 flex-1 border mt-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  />
                </div>
              </div>
              <div className="flex mt-1 gap-x-5 items-center">
                <label className=" w-20  text-sm font-medium text-gray-900">
                  Designation
                </label>
                <input
                  disable
                  name="raisedBy"
                  value={formData.raisedBydesignation}
                  className="bg-gray-200 border w-80 mt-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5"
                />
              </div>
            </div>
          </div>
          {formData?.approvers && (
            <ApproverActionSectionSi
              prnumber={siRequest?.prnumber}
              approverData={formData.approvers}
            />
          )}
        </div>
      </form>

      <Modal
        onClose={() => setOpenSuccessModal(false)}
        isOpen={openSuccessModal}
      >
        {/* Modal content based on success or loading */}
        {/* ... */}
      </Modal>
    </section>
  );
};

export default SiFormReview;
