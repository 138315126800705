import React from "react";

const AppSelectInput = ({
  onChange,
  label,
  name,
  data,
  getter,
  isDisabled,
  value,
  isVertical,
  isError,
}) => {
  return (
    <div
      className={`${
        isVertical ? "flex-col" : "flex-row items-center"
      } flex gap-x-5 `}
    >
      <label htmlFor={name} className={`${isDisabled && "text-gray-500"}`}>
        {label}
      </label>
      <select
        value={value}
        disabled={isDisabled}
        name={name}
        onChange={onChange}
        className={`mt-1 ${
          isError && "border-red-400 border-2"
        } bg-gray-200  rounded-md border-gray-400 border px-2 py-2  w-[300px]`}
      >
        <option value="" key={0} hidden>Select</option>
        {data.map((item, i) => (
          <option key={i}>{item[getter]}</option>
        ))}
      </select>
    </div>
  );
};

export default AppSelectInput;
