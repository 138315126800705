import { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import logo from "../../assets/logo.png";
import userImg from "../../assets/logo2.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/action";

function Navbar() {
  const initialNavigation = [
    {
      name: "PR Dashboard",
      href: "/dashboard/pr-dashboard",
    },
    {
      name: "Start New Purchase Request",
      href: "/dashboard/new-pr-form",
    },
    {
      name: "SI Dashboard",
      href: "/dashboard/si-dashboard",
    },
    // {
    //   name: "PR Approver",
    //   href: "/dashboard/review-pr?prnumber=PR-230822004",
    // },
  ];

  const [navigations, setNavigations] = useState(initialNavigation);
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  const email = useSelector((state) => state.auth.email);
  const username = useSelector((state) => state.auth.username);
  const role = useSelector((state) => state.auth.role);

  const user = {
    name: username,
    email: email,
    username: username,
    imageUrl: { userImg },
  };

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleHome = () => {
    navigate("/");
  };

  useEffect(() => {
    // approver
    if (role === "approver") {
      setNavigations([
        {
          name: "PR Dashboard",
          href: "/dashboard/pr-dashboard",
        },
        {
          name: "Start New Purchase Request",
          href: "/dashboard/new-pr-form",
        },
        {
          name: "SI Dashboard",
          href: "/dashboard/si-dashboard",
        },
        {
          name: "PR Approvals",
          href: "/dashboard/pr-approvals",
        },
        {
          name: "SI Approvals",
          href: "/dashboard/si-approvals",
        },
      ]);
    }
    // admin
    if (role === "admin") {
      setNavigations([
        {
          name: "PR Dashboard",
          href: "/dashboard/pr-dashboard",
        },
        {
          name: "Start New Purchase Request",
          href: "/dashboard/new-pr-form",
        },
        {
          name: "SI Dashboard",
          href: "/dashboard/si-dashboard",
        },
        {
          name: "PR Approvals",
          href: "/dashboard/pr-approvals",
        },
        {
          name: "SI Approvals",
          href: "/dashboard/si-approvals",
        },
        {
          name: "Admin Console",
          href: "/dashboard/admin-console",
        },
      ]);
    }
  }, [role]);

  return (
    <Disclosure as="nav" className="bg-[#f2f3f4] ">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-[90rem] px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img
                    className="h-12 w-auto cursor-pointer "
                    src={logo}
                    alt="Visy"
                    onClick={handleHome}
                  />
                </div>
                <div className="hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-3">
                    {navigations.map((item) => (
                      <Link key={item.name} to={item.href}>
                        <button
                          className={classNames(
                            pathname === item.href
                              ? "bg-blue-900 text-white cursor-pointer transition-colors duration-300 shadow"
                              : "bg-white bg-opacity-50 text-gray-600 hover:bg-gray-700 hover:text-white transition-colors duration-300 shadow drop-shadow-lg",
                            "rounded-md px-2 py-2 text-[12px] font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </button>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>

              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <div className="flex items-center">
                    <h4 className="text-sm   tracking-tight text-slate-700 mr-1">
                      Welcome {username}
                    </h4>
                  </div>
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none ring-1 focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full "
                          src={userImg}
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="/"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-1 text-base text-red-700"
                              )}
                              onClick={handleLogout}
                            >
                              Sign Out
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
              {initialNavigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                  onClick={item.onclick}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
            <div className="border-t border-gray-700 pb-3 pt-4">
              <div className="flex items-center px-5">
                <div className="flex-shrink-0 drop-shadow-lg shadow-black ">
                  <img
                    className="h-10 w-10 rounded-full "
                    src={userImg}
                    alt="user"
                  />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium leading-none text-white">
                    {user.name}
                  </div>
                  <div className="text-sm font-medium leading-none text-gray-400">
                    {user.email}
                  </div>
                </div>
              </div>
              <div className="mt-3 space-y-1 px-2">
                <Disclosure.Button
                  as="a"
                  className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white cursor-pointer"
                  onClick={handleLogout}
                >
                  Sign Out
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default Navbar;
