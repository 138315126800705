import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { login } from "../redux/action";
import { toast, Toaster } from "react-hot-toast";
import logo from "../assets/logo.png";
// import logo from "../assets/dashboard.png";
import { useMyContext } from "../context/storeApproverValue";

const LoginPage = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [searchParams] = useSearchParams();
  const toReviewPr = searchParams.get("toReviewPr");
  const toViewPr = searchParams.get("toViewPr");
  const toReviewSi = searchParams.get("toReviewSi");
  const prnumber = searchParams.get("prnumber");

  const { setToreview, setPrnumber, setToreviewSi, setView } = useMyContext();

  const handleLogin = async () => {
    try {
      const response = await dispatch(login(email, password));
      if (response === "success") {
        setIsFormSubmitted(true);
        toast.success("Login Successful");
      } else {
        toast.error("Invalid Credentials");
      }
    } catch (error) {
      console.error(error);
      toast.error("Login failed. Please check your credentials and try again.");
    }
  };

  // const loggedIn = useSelector((state) => state.auth.isLoggedIn);

  // const loginError = useSelector((state) => state.auth.error);
  // const role = useSelector((state) => state.auth.role);

  useEffect(() => {
    setPrnumber(prnumber);
    setToreview(toReviewPr);
    setToreviewSi(toReviewSi);
    setView(toViewPr);
  }, [prnumber, toReviewPr, toReviewSi, toViewPr]);

  return (
    <>
      <section className="bg-gray-50 flex items-center justify-center min-h-screen">
        <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6">
          <img className="w-auto h-20 mr-1 my-8 " src={logo} alt="logo" />

          <h1 className="text-xl font-medium leading-tight tracking-tight text-gray-900 mb-4">
            Sign in to your account
          </h1>
          <form className="space-y-4">
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Your email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="name@gmail.com"
                required
                value={email}
                autoComplete="current-email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Password
              </label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="••••••••"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                required
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button
              type="button"
              onClick={handleLogin}
              className="w-full bg-[#15429b]  hover:bg-blue-950 text-white font-medium rounded-lg my-2 text-sm px-5 py-2.5 text-center"
            >
              <span className="drop-shadow-lg shadow-black">Sign in</span>
            </button>
          </form>
        </div>
      </section>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default LoginPage;
