import React, { useState, useEffect } from "react";
import AppTextInput from "../../../UI/Input/AppTextInput";
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import AppSelectInput from "../../../UI/Input/AppSelectInput";
import SIApproverSelector from "./SIApproverSelector";
import { useSelector, useDispatch } from "react-redux";
import {
  generaterSiNumber,
  performEditSiRequest,
  performEditSiWithApprover,
  getSiById,
} from "../../../../redux/action";
import AppTextArea from "../../../UI/Input/AppTextArea";
import SIFormDynamicValues from "./SIFormDynamicValue";
import Modal from "../../../UI/Modal";
import { BsCheckCircle } from "react-icons/bs";
import toast from "react-hot-toast";

const EditSiteInstructionForm = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const [selectedApprovers, setSelectedApprovers] = useState([]);
  const [submittedForApproval, setSubmittedForApproval] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const [modalContent, setModalContent] = useState({
    title: "",
    message: "",
    prnumber: "",
  });

  const [errors, setErrors] = useState(null);

  const prNumber = searchParams.get("prnumber");
  // console.log(prNumber);

  useEffect(() => {
    dispatch(getSiById(prNumber));
  }, [dispatch, prNumber]);

  const siRequest = useSelector((state) => state.singleSi.siRequests);

  //  console.log(siRequest);
  useEffect(() => {
    if (siRequest) {
      setFormData({
        ...siRequest,
        siDate: siRequest?.siDate && new Date(siRequest?.siDate).toISOString(),
      });
    }
  }, [siRequest]);

  const initialDetail = {
    prnumber: "",
    sino: "",
    sititle: "",
    siDate: "",
    projectnumber: "",
    companyname: "",
    descriptionOfWork: "",
    timeExtension: {
      value: "",
      time: "",
    },
    totalValueSiteInstruction: {
      value: "",
      currency: "",
    },
    associatedWorks: "",
    contractScope: "",
    resonForInstruction: "",
    raisedBy: {
      name: "",
      team: "",
      designation: "",
    },
  };

  const [formData, setFormData] = useState(initialDetail);

  const handleApproverSelection = (approversDetails) => {
    setSelectedApprovers(approversDetails);
  };

  const handleInputChange = (event, extraName) => {
    const { name, value } = event.target;

    setFormData((prevData) => {
      let updatedData;
      if (
        ["timeExtension", "totalValueSiteInstruction", "raisedBy"].includes(
          name
        )
      ) {
        updatedData = {
          ...prevData,
          [name]: {
            ...prevData[name],
            [extraName || "value"]: value,
          },
        };
      } else if (name === "sidate") {
        updatedData = {
          ...prevData,
          [name]: new Date(value).toISOString().split("T")[0],
        };
      } else {
        updatedData = {
          ...prevData,
          [name]: value,
        };
      }

      if (submittedForApproval) {
        const { newErrors, newErrorMessages } = validateForm(
          updatedData,
          selectedApprovers
        );
        setErrors(newErrors);
        setErrorMessages(newErrorMessages);
      }

      return updatedData;
    });
  };

  const handleFormSave = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      const updatedFormData = {
        ...formData,
        prnumber: prNumber,
      };
      await dispatch(performEditSiRequest(updatedFormData));

      setIsLoading(false);
      setModalContent({
        title: "Site Instruction updated Successfully!",
        message: "Site Instruction has been saved.",
      });

      setOpenSuccessModal(true);
      setFormData(initialDetail);
    } catch (error) {
      console.error("Error submitting form:", error);
      setIsLoading(false);
    }
  };

  const handleSubmitForApproval = async (e) => {
    e.preventDefault();
    setSubmittedForApproval(true);
    const { newErrors, newErrorMessages } = validateForm(
      formData,
      selectedApprovers
    );
    setErrors(newErrors);
    setErrorMessages(newErrorMessages);
    if (Object.keys(newErrors).length > 0) {
      toast.error("Fill the details correctly");
      return;
    }
    try {
      setIsLoading(true);
      const updatedFormData = {
        ...formData,
        sistatus: "Pending",
        approvers: selectedApprovers,
      };
      // console.log(updatedFormData);
      await dispatch(performEditSiWithApprover(updatedFormData));
      setModalContent({
        title: "Site Instruction Submitted For Approval Successfully!",
        message: "Site Instruction has been saved.",
      });
      setOpenSuccessModal(true);
      setFormData(initialDetail);
      setIsLoading(false);
    } catch (error) {
      console.error("Error submitting form:", error);
      setIsLoading(false);
    }
  };

  const validateForm = (data, approvers) => {
    let newErrors = {};
    let newErrorMessages = {};

    if (!data.sititle) {
      newErrors.sititle = true;
      newErrorMessages.sititle = "SI Title is required";
    }
    if (!data.projectnumber) {
      newErrors.projectnumber = true;
      newErrorMessages.projectnumber = "Project Number is required";
    }
    if (!data.companyname) {
      newErrors.companyname = true;
      newErrorMessages.companyname = "Company Name is required";
    }
    if (!data.descriptionOfWork) {
      newErrors.descriptionOfWork = true;
      newErrorMessages.descriptionOfWork = "Description of Work is required";
    }
    if (!data.timeExtension?.value) {
      newErrors.timeExtension = true;
      newErrorMessages.timeExtension = "Time Extension value is required";
    } else if (isNaN(data.timeExtension.value)) {
      newErrors.timeExtension = true;
      newErrorMessages.timeExtension = "Time Extension value must be a number";
    }
    if (!data.timeExtension?.time) {
      newErrors.timeExtension = true;
      newErrorMessages.timeExtensionTime = "Time Extension time is required";
    }
    if (!data.totalValueSiteInstruction?.value) {
      newErrors.totalValueSiteInstruction = true;
      newErrorMessages.totalValueSiteInstruction =
        "Total Value Site Instruction value is required";
    } else if (isNaN(data.totalValueSiteInstruction.value)) {
      newErrors.totalValueSiteInstruction = true;
      newErrorMessages.totalValueSiteInstruction =
        "Total Value Site Instruction value must be a number";
    }
    if (!data.totalValueSiteInstruction?.currency) {
      newErrors.totalValueSiteInstruction = true;
      newErrorMessages.totalValueSiteInstructionCurrency =
        "Total Value Site Instruction currency is required";
    }
    if (!data.associatedWorks) {
      newErrors.associatedWorks = true;
      newErrorMessages.associatedWorks = "Associated Works is required";
    }
    if (!data.contractScope || data.contractScope === "Select") {
      newErrors.contractScope = true;
      newErrorMessages.contractScope =
        "Contract Scope is required and must be valid";
    }
    if (!data.resonForInstruction) {
      newErrors.resonForInstruction = true;
      newErrorMessages.resonForInstruction =
        "Reason for Instruction is required";
    }
    if (!data.raisedBy?.name) {
      newErrors.name = true;
      newErrorMessages.name = "Name is required";
    }
    if (!data.raisedBy?.team) {
      newErrors.team = true;
      newErrorMessages.team = "Team is required";
    }
    if (!data.raisedBy?.designation) {
      newErrors.designation = true;
      newErrorMessages.designation = "Designation is required";
    }
    if (!approvers?.length) {
      newErrors.approver = true;
      newErrorMessages.approver = "Approvers are required";
    }

    return { newErrors, newErrorMessages };
  };

  return (
    <section className="shadow-lg p-5 bg-white">
      <h1 className="text-2xl font-bold leading-6 text-slate-700 mb-8 text-center">
        Edit Visy Site Instruction Request
      </h1>

      <form
        onSubmit={handleFormSave}
        className="flex flex-col justify-center items-center gap-y-5 mt-3 w-full"
      >
        <div className="w-[50rem] flex flex-col gap-y-5">
          <SIFormDynamicValues
            handleInputChange={handleInputChange}
            siDate={formData.siDate}
            siNumber={siRequest.sino}
          />

          <AppTextInput
            name={"sititle"}
            onChange={handleInputChange}
            label={"SI TITLE"}
            value={formData.sititle}
            isError={errors?.sititle}
          />
          <AppTextInput
            name={"projectnumber"}
            onChange={handleInputChange}
            label={"PROJECT NO."}
            value={formData.projectnumber}
            isError={errors?.projectnumber}
          />
          <AppTextInput
            name={"companyname"}
            onChange={handleInputChange}
            label={"TO COMPANY NAME"}
            value={formData.companyname}
            isError={errors?.companyname}
          />
          <p>
            Please note: You are directed to execute the following Site
            Instruction promptly.
            <br /> This Site Instruction indicates approved time or cost
            variation.
          </p>
          <AppTextArea
            isBold={true}
            onChange={handleInputChange}
            name={"descriptionOfWork"}
            label={"DESCRIPTION OF WORKS:"}
            value={formData.descriptionOfWork}
            row={20}
            isError={errors?.descriptionOfWork}
          />

          <div>
            <p className="text-sm font-semibold text-gray-900">
              REASON FOR INSTRUCTION:
            </p>
            <div className="flex justify-between mt-2 gap-x-5 items-center">
              <div className="flex items-center gap-x-3">
                <p className="font-medium">Time Extension</p>
                <input
                  placeholder="0"
                  onChange={(e) => handleInputChange(e, "value")}
                  className={`bg-[#e2efda] ${
                    errors?.timeExtension && "border-red-700"
                  } font-semibold text-center placeholder:text-center  border border-gray-400 rounded-md px-2 py-1 w-20`}
                  name="timeExtension"
                  value={formData.timeExtension?.value || ""}
                />
                <select
                  name="timeExtension"
                  onChange={(e) => handleInputChange(e, "time")}
                  className=" bg-[#e2efda] font-semibold rounded-md border-gray-400 border p-1 w-[80px]"
                  value={formData.timeExtension?.time || ""}
                >
                  <option value="Hours">Hours</option>
                  <option value="Days">Days</option>
                </select>
              </div>
              <div className="flex items-center gap-x-3">
                <p className="font-medium">Total Value Site Instruction</p>
                <input
                  placeholder="0.00"
                  onChange={(e) => handleInputChange(e, "value")}
                  className={`bg-[#e2efda] ${
                    errors?.totalValueSiteInstruction && "border-red-700"
                  } font-semibold text-right border border-gray-400 rounded-md px-2 py-1 w-32`}
                  name="totalValueSiteInstruction"
                  value={formData.totalValueSiteInstruction?.value}
                />
                <select
                  onChange={(e) => handleInputChange(e, "currency")}
                  name="totalValueSiteInstruction"
                  className="bg-[#e2efda] font-semibold rounded-md border-gray-400 border p-1 w-[100px]"
                  value={formData.totalValueSiteInstruction?.currency}
                >
                  <option value="AUD">AUD</option>
                  <option value="NZD">NZD</option>
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                  <option value="GBP">GBP</option>
                  <option value="CNY">CNY</option>
                  <option value="IDR">IDR</option>
                  <option value="VND">VND</option>
                  <option value="MYR">MYR</option>
                </select>
              </div>
            </div>
          </div>
          <AppTextArea
            onChange={handleInputChange}
            name={"associatedWorks"}
            label={"ASSOCIATED WORKS:"}
            row={10}
            isBold={true}
            value={formData.associatedWorks}
            isError={errors?.associatedWorks}
          />
          <AppSelectInput
            isVertical={true}
            name={"contractScope"}
            onChange={handleInputChange}
            getter={"val"}
            label={"Is work covered within current contract scope?"}
            data={[{ val: "In Scope" }, { val: "Out of Scope" }]}
            value={formData.contractScope}
            isError={errors?.contractScope}
          />
          <AppTextArea
            onChange={handleInputChange}
            name={"resonForInstruction"}
            label={"REASON FOR INSTRUCTION:"}
            row={10}
            isBold={true}
            value={formData.resonForInstruction}
            isError={errors?.resonForInstruction}
          />
          <div>
            <p className="font-semibold text-gray-900">Raised By:</p>
            <div className="ml-10">
              <div className="flex gap-x-1 justify-between  mt-3">
                <div className="flex gap-x-5 items-center">
                  <label className="w-20 text-sm font-medium text-gray-900">
                    Name
                  </label>
                  <input
                    onChange={(e) => handleInputChange(e, "name")}
                    name="raisedBy"
                    value={formData.raisedBy?.name}
                    className={`bg-gray-200 ${
                      errors?.name && "border-red-700"
                    } w-80 border mt-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5`}
                  />
                </div>
                <div className="flex gap-x-5 items-center">
                  <label className=" text-sm font-medium text-gray-900">
                    of the Team
                  </label>
                  <input
                    onChange={(e) => handleInputChange(e, "team")}
                    name="raisedBy"
                    value={formData.raisedBy?.team}
                    className={`bg-gray-200 ${
                      errors?.team && "border-red-700"
                    } flex-1 border mt-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                  />
                </div>
              </div>
              <div className="flex mt-1 gap-x-5 items-center">
                <label className=" w-20  text-sm font-medium text-gray-900">
                  Designation
                </label>
                <input
                  onChange={(e) => handleInputChange(e, "designation")}
                  name="raisedBy"
                  value={formData.raisedBy?.designation}
                  className={`bg-gray-200 ${
                    errors?.designation && "border-red-700"
                  } border w-80 mt-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5`}
                />
              </div>
            </div>
          </div>
          <button
            className="bg-[#194099] w-32 shadow-md rounded-md text-white px-5 py-2 mt-5"
            type="submit"
          >
            Save & Exit
          </button>
        </div>
        <SIApproverSelector
          isError={errors?.approver}
          onApproverSelection={handleApproverSelection}
        />

        <button
          className="bg-[#3bb75a] shadow-md rounded-md text-white px-5 py-2 mt-4 hover:bg-[#2b8040]"
          type="button"
          onClick={handleSubmitForApproval}
        >
          Submit for Approval
        </button>
        {errorMessages &&
          Object.values(errorMessages).some((error) => error) && (
            <div className="mt-4 p-4 border border-red-500 bg-red-100 rounded-md w-[28rem] flex flex-col items-start">
              <h3 className="text-md font-semibold text-red-700 mb-2">
                Please check the following :
              </h3>
              <ul className="list-disc pl-5 text-red-700 space-y-1">
                {Object.entries(errorMessages).map(([key, error]) =>
                  error ? (
                    <li key={key} className="text-sm">
                      {error}
                    </li>
                  ) : null
                )}
              </ul>
            </div>
          )}
      </form>
      <Modal
        onClose={() => setOpenSuccessModal(false)}
        isOpen={openSuccessModal}
      >
        {isLoading ? (
          <>
            <div className="flex items-center justify-center h-full ">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-green-400"></div>
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-center mb-5 text-4xl">
              <BsCheckCircle className="text-green-400" />
            </div>
            <p className="text-center font-bold">{modalContent.title}</p>
            <p className="text-center text-[15px]">{modalContent.sinumber}</p>
            <div className="flex text-[14px] mb-5 justify-center mt-5 gap-x-5 items-center">
              <Link to={"/dashboard/si-dashboard"}>
                <button className="bg-[#1e3a8a] text-white px-2 py-2 rounded-md">
                  Go to Dashboard
                </button>
              </Link>
            </div>
          </>
        )}
      </Modal>
    </section>
  );
};

export default EditSiteInstructionForm;
