import React, { useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";

const FormDynamicValues = ({
  prnumber,
  prdate,
  submittedby,
  status,
  handleInputChange,
  disable,
  error,
}) => {
  const [isEditDate, setIsEditDate] = useState(false);

  const getStatusBadge = (status) => {
    switch (status) {
      case "Pending":
        return "bg-orange-100 text-orange-800";
      case "Approved":
        return "bg-green-100 text-green-800";
      case "Rejected":
        return "bg-gray-100 text-gray-800";
      case "Closed":
        return "bg-blue-100 text-blue-800";
      case "Cancelled":
        return "bg-red-100 text-red-800";
      case "Draft":
      default:
        return "bg-yellow-100 text-yellow-800";
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);

    return `${day}/${month}/${year}`;
  };

  const convert = (newDate) => {
    const date = new Date(newDate);
    const format = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;
    return format;
  };

  return (
    <div className="border border-gray-200 p-4 w-80 text-sm rounded-lg shadow-md bg-white">
      <div className="flex justify-between items-center mb-2">
        <p className="font-medium text-gray-700">PR No.</p>
        <p className="text-gray-900">{prnumber}</p>
      </div>

      <div className="flex justify-between items-center mb-2">
        <p className="font-medium text-gray-700">PR Date</p>
        <div className="flex items-center gap-x-2">
          {isEditDate && !disable ? (
            <input
              value={prdate}
              onChange={(val) => {
                handleInputChange(val);
              }}
              className={`bg-gray-100 border ${
                error && "border-red-700"
              } px-2 py-1 rounded-md text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500`}
              type="date"
              name="prdate"
            />
          ) : (
            <p className="text-gray-900">{convert(prdate)}</p>
          )}
          {!disable && (
            <button
              onClick={() => {
                const e = {
                  target: {
                    name: "prdate",
                    value: new Date().toISOString(),
                  },
                };
                handleInputChange(e);
                setIsEditDate(!isEditDate);
              }}
              className="text-blue-500 hover:text-blue-700"
            >
              {isEditDate ? <RxCross2 /> : <BiEditAlt />}
            </button>
          )}
        </div>
      </div>

      <div className="flex justify-between items-center mb-2">
        <p className="font-medium text-gray-700">Submitted By</p>
        <p className="text-gray-900">{submittedby}</p>
      </div>

      <div className="flex justify-between items-center">
        <p className="font-bold text-gray-700">Current Status:</p>
        <span
          className={`inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium ${getStatusBadge(
            status
          )}`}
        >
          {status ? status : "Draft"}
        </span>
      </div>
    </div>
  );
};

export default FormDynamicValues;
