import React, { useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";

const SIFormDynamicValues = ({
  siNumber,
  setSiDate,
  siDate,
  status,
  handleInputChange,
}) => {
  const [isEditDate, setIsEditDate] = useState(false);

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const val = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;

    return val;
  };

  return (
    <div className="border flex flex-col justify-center border-gray-200 px-3 w-72 text-[14px] py-2 gap-y-2 rounded-md">
      <div className="flex">
        <p className="w-36">SI No.</p>
        <p>{siNumber}</p>
      </div>

      <div className="flex">
        <p className="w-36">SI Date</p>
        <div className="flex gap-x-2 items-center">
          {isEditDate ? (
            <>
              <input
                value={siDate}
                onChange={handleInputChange}
                className="bg-gray-200 border text-[14px] px-2 mt-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block"
                type="date"
                name="siDate"
              />
              <button
                type="button"
                onClick={() => setIsEditDate(false)}
                className="cursor-pointer text-blue-800"
              >
                <RxCross2 />
              </button>
            </>
          ) : (
            <>
              <p>{formatDate(siDate)}</p>
              <button
                type="button"
                onClick={() => setIsEditDate(true)}
                className="cursor-pointer text-blue-800"
              >
                <BiEditAlt />
              </button>
            </>
          )}
        </div>
      </div>

      <div className="flex">
        <p className="font-bold w-36">Current Status:</p>
        <p className="text-orange-700 font-bold">{status ? status : "Draft"}</p>
      </div>
    </div>
  );
};

export default SIFormDynamicValues;
