import React from "react";

const AppTextArea = ({
  row,
  isSide,
  onChange,
  name,
  type,
  label,
  required,
  isBold,
  value,
  isError,
}) => {
  return (
    <div>
      <label
        className={`${
          isBold && "font-semibold"
        } block text-sm font-medium text-gray-900`}
        htmlFor={name}
      >
        {label}
      </label>
      <textarea
        rows={row}
        className={`bg-gray-200 ${
          isError && "border-red-700"
        } border mt-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
        type={type}
        name={name}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default AppTextArea;
