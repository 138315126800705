import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaRegFilePdf } from "react-icons/fa6";
import { BiEditAlt } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import PRFilters from "./PRFilters";
import MyDataGrid from "../../../UI/MyDataGrid";
import Pagination from "../../../UI/Pagination";
import { useSelector } from "react-redux";
import Loader from "../../../UI/Loader";
import moment from "moment";
import ViewPr from "../viewPr";
import ViewExport from "../viewExport.jsx";
import useSwr from "swr";
import CancelPrModal from "../../../UI/CancelPrModal";
import { toast } from "react-hot-toast";
import axiosInstance from "../../../utils/axiosInstance";
import { useDispatch } from "react-redux";
import { downloadPrPdf } from "../../../../redux/action.js";
const PRDashboard = () => {
  const role = useSelector((state) => state.auth.role);
  const dispatch = useDispatch();
  const [openViewModel, setOpenViewModel] = useState(false);
  const [isOpenCancel, setOpenCancel] = useState(false);
  const [selectedPr, setSelectedPr] = useState("");
  const [trigger, setTrigger] = useState(false);
  const [purchaseRequestsData, setPurchaseRequestsData] = useState([]);
  const [exportModal, setExportModal] = useState(false);

  const initialSearch = {
    showRecords: "30",
    status: "All",
    inScope: "All",
    search: "",
    from: "",
    to: "",
  };

  const [filter, setFilter] = useState(initialSearch);
  const [inputFilter, setInputFilter] = useState(initialSearch);
  const [pageNo, setPageNo] = useState(0);

  async function fetchPR() {
    try {
      const response = await axiosInstance.get(
        `/purchase-request?pageNo=${pageNo}&seachText=${filter?.search}&status=${filter?.status}&fromDate=${filter?.from}&toDate=${filter?.to}`
      );

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  const { data: prData, isLoading } = useSwr(
    ["pr", pageNo, filter.search, filter.to, filter.from, trigger],
    fetchPR
  );

  useEffect(() => {
    if (prData?.data?.length > 0) {
      const data = prData?.data?.map((row) => ({
        ...row,
        id: row.prnumber + "&" + row.prstatus,
      }));

      setPurchaseRequestsData(data);
    }
  }, [prData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let filters = { ...inputFilter };
    filters[name] = value;
    setInputFilter(filters);
  };

  const handlePdfDownload = async (id) => {
    try {
      await dispatch(downloadPrPdf(id));
      toast.success("PDF Download Started");
    } catch (error) {
      console.error(error);
      toast.error("Failed to start PDF download");
    }
  };

  const columns = [
    {
      field: "prnumber",
      headerName: "PR No.",
      width: 120,
      renderCell: (params) => {
        const prNo = params.value;
        return <p className="  font-medium">{prNo}</p>;
      },
    },
    {
      field: "prdate",
      headerName: "PR Date",
      width: 100,
      renderCell: (params) => {
        const prDateStr = params.value;

        if (!prDateStr) {
          return <p>N/A</p>;
        }

        const date = new Date(prDateStr);

        const val = `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()}`;

        return <p>{val}</p>;
      },
    },

    {
      field: "projectnumber",
      headerName: "Project No.",
      width: 100,
    },
    {
      field: "vendor",
      headerName: "Vendor Name",
      width: 140,
    },
    {
      field: "inScope",
      headerName: "In-Scope?",
      width: 90,
      renderCell: (params) => {
        const status = params.value;
        return <p>{status}</p>;
      },
    },
    {
      field: "duedate",
      headerName: "Due Date",
      width: 100,
      renderCell: (params) => {
        const dueDateStr = params.value;

        if (!dueDateStr) {
          return <p></p>;
        }

        const formattedDate = moment(dueDateStr).format("DD/MM/YYYY");
        return <p>{formattedDate}</p>;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 100,
    },
    {
      field: "prstatus",
      headerName: "PR Status",
      width: 100,
      renderCell: (params) => {
        const status = params.value;

        let bgColor = "bg-green-500";
        let textColor = "text-white";
        if (status === "Draft") {
          bgColor = "bg-orange-200";
          textColor = "text-black";
        }

        if (status === "Pending") {
          bgColor = "bg-yellow-500";
          textColor = "text-white";
        }
        if (status === "Rejected") {
          bgColor = "bg-red-600";
          textColor = "text-white";
        }
        if (status === "Closed") {
          bgColor = "bg-gray-500";
          textColor = "text-white";
        }
        if (status === "Cancelled") {
          bgColor = "bg-gray-600";
          textColor = "text-white";
        }
        return (
          <p
            className={`${bgColor}  ${textColor} px-2 text-[12px] py-1 rounded-md `}
          >
            {status}
          </p>
        );
      },
    },
    {
      field: "sinumber",
      headerName: "Linked SI No.",
      width: 110,
      renderCell: (params) => {
        const sinumber = params.value;

        if (sinumber) {
          return <p className="  font-medium">{sinumber}</p>;
        } else {
          return "Not Linked";
        }
      },
    },
    {
      field: "id",
      headerName: "Actions Section:",
      width: 350,
      renderCell: (params) => {
        const value = params.value;
        const prstatus = params.row.prstatus;
        const [id] = value.split("&");
        let color = "";
        let showLink = false;
        let disableDownload = true;
        let showCancel = true;

        if (prstatus === "Approved") {
          showLink = false;
          disableDownload = false;
        }
        if (prstatus === "Draft" || prstatus === "Rejected") {
          color = "text-[#1e3a8a]";
          showLink = true;
        }

        if (prstatus === "Closed" || prstatus === "Cancelled") {
          showCancel = false;
        }

        if (prstatus === "Closed" || prstatus === "Cancelled") {
          disableDownload = false;
        }

        return (
          <div className="flex gap-x-4">
            <button
              onClick={() => {
                setSelectedPr(id);
                setOpenViewModel(true);
              }}
            >
              <AiOutlineEye className="text-[#1e3a8a] text-2xl" />
            </button>
            {showLink ? (
              <Link to={`/dashboard/edit-pr?prnumber=${id}`}>
                <button className="">
                  <BiEditAlt
                    className={`${color || "text-gray-400"} text-2xl`}
                  />
                </button>
              </Link>
            ) : (
              <button className="">
                <BiEditAlt
                  className={`${color || "text-gray-400"} text-2xl `}
                />
              </button>
            )}
            <button
              disabled={disableDownload}
              className="text-red-600 text-2xl disabled:text-gray-500"
              onClick={() => handlePdfDownload(id)}
            >
              <FaRegFilePdf />
            </button>
            {role === "admin" && (
              <button
                disabled={!showCancel}
                onClick={() => {
                  setOpenCancel(true);
                  setSelectedPr(id);
                }}
                className="text-[14px] text-blue-600 underline disabled:text-gray-500"
              >
                Cancel
              </button>
            )}
            {role === "admin" && prstatus === "Approved" && (
              <Link to={`/dashboard/review-pr?prnumber=${id}`}>
                <button className="text-[14px] text-blue-600 underline">
                  Update PO
                </button>
              </Link>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (isLoading) return;

    let filteredData = prData?.data;

    if (filteredData?.length === 0) return;

    if (inputFilter.status !== "All") {
      filteredData = filteredData?.filter(
        (res) => res.prstatus === inputFilter.status
      );
    }

    if (inputFilter.inScope !== "All") {
      filteredData = filteredData?.filter((res) => {
        if (inputFilter.inScope === "Yes") {
          return res.inScope === "In Scope";
        } else if (inputFilter.inScope === "No") {
          return res.inScope === "Out of Scope";
        }
        return true;
      });
    }

    if (inputFilter.showRecords) {
      const today = new Date();
      const dateAgo = new Date();
      dateAgo.setDate(dateAgo.getDate() - 30);
      filteredData = filteredData.filter((item) => {
        const createdAtDate = new Date(item.createdAt);
        return createdAtDate >= dateAgo && createdAtDate <= today;
      });
    }

    const data = filteredData.map((row) => ({
      ...row,
      id: row.prnumber + "&" + row.prstatus,
    }));

    setPurchaseRequestsData(data);
  }, [
    inputFilter.showRecords,
    inputFilter.status,
    inputFilter.inScope,
    pageNo,
  ]);

  const handleSearch = () => {
    setFilter(inputFilter);
  };

  function handleClear() {
    setInputFilter(initialSearch);
    setFilter(initialSearch);
  }

  const handleExport = () => {
    if (purchaseRequestsData.length > 0) {
      setExportModal(true);
    } else {
      toast.error("PR not found");
    }
  };

  return (
    <div className="w-full">
      <div className="min-h-screen h-100% bg-white py-3">
        <h1 className=" font-bold text-center text-2xl ">
          Purchase Order Request Tracker
        </h1>
        <main className="py-8 w-full  px-4 sm:px-8">
          <div className="w-full">
            <PRFilters
              isShowClearBtn={
                inputFilter?.to
                  ? true
                  : inputFilter?.from
                  ? true
                  : inputFilter?.search
                  ? true
                  : false
              }
              handleClear={handleClear}
              handleSearch={handleSearch}
              handleInputChange={handleInputChange}
              handleExport={handleExport}
              filter={inputFilter}
            />
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <MyDataGrid
                  bgColor={"#1e3a8a"}
                  rows={purchaseRequestsData}
                  columns={columns}
                />
              </>
            )}
            <div className="flex justify-center">
              <Pagination
                bgColor={"#1e3a8a"}
                setPageNo={setPageNo}
                totalItem={prData?.total}
              />
            </div>
          </div>
        </main>
      </div>
      {openViewModel && selectedPr && (
        <ViewPr
          prNumber={selectedPr}
          setOpen={setOpenViewModel}
          isOpen={openViewModel}
        />
      )}
      {exportModal && (
        <ViewExport isOpen={exportModal} setOpen={setExportModal} type={"PR"} />
      )}
      {
        <CancelPrModal
          prnumber={selectedPr}
          isOpen={isOpenCancel}
          setOpen={setOpenCancel}
          setTrigger={setTrigger}
        />
      }
    </div>
  );
};

export default PRDashboard;
