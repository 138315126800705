import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  email: '',
  role: '',
  username: '',
  userid: '',
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.isLoggedIn = true;
      state.userid = action.payload.user_id;
      state.email = action.payload.user_email;
      state.role = action.payload.role;
      state.username = action.payload.username;
      state.error = null;
    },
    loginFailure: (state, action) => {
      state.isLoggedIn = false;
      state.error = action.payload.error;
    },
    logOut: (state) => {
      state.isLoggedIn = false;
      state.email = '';
      state.role = '';
      state.username = '';
      state.user_id = '';
      state.error = null;
    },
  },
});

export const { loginSuccess, loginFailure, logOut } = authSlice.actions;

export default authSlice.reducer;
