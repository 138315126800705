import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import FormDynamicValues from "./FormDynamicValues";
import { useDispatch, useSelector } from "react-redux";
import ApproverSelector from "./ApproverSelector";
import ManagerOnlyInput from "./ManagerOnlyInput";
import AppTextInput from "../../../UI/Input/AppTextInput";
import AppDateInput from "../../../UI/Input/AppDateInput";
import MaterialSelector from "./MaterialSelector";
import DetailsFormTable from "./DetailsFormTable";
import {
  deleteFile,
  editUploadFiles,
  fetchFiles,
  getPrById,
  performEditPurchaseRequest,
  performEditPurchaseRequestWithApprover,
} from "../../../../redux/action";

import { toast } from "react-hot-toast";
import EditDropZone from "../../../UI/EditDropZone";
import { MdDelete } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import Loader from "../../../UI/Loader";
import ExternalContractor from "./ExternalContractor";

const initialDetail = {
  quantity: "",
  description: "",
  unitprice: "",
  currency: "",
  amount: "",
  wbs: "",
  wbsname: "",
};
const initialContractorState = {
  externalContractor: false,
  ContractorsName: "",
  ContractorsEmail: "",
  SubContractorsEmail: "",
};

const EditPurchaseRequestForm = () => {
  const [selectedApprovers, setSelectedApprovers] = useState([]);
  const [searchParams] = useSearchParams();
  const [files, setFiles] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});
  const [externalContractor, setExternalContractor] = useState(
    initialContractorState
  );
  const [isSupplierSelected, setIsSupplierSelected] = useState("no");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const prNumber = searchParams.get("prnumber");
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getPrById(prNumber));
    dispatch(fetchFiles(prNumber))
      .then((res) => {
        setFiles(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [prNumber, dispatch]);

  const handleApproverSelection = (approversDetails) => {
    setSelectedApprovers(approversDetails);
  };

  const purchaseRequest = useSelector(
    (state) => state.signlePr.purchaseRequests
  );
  const si = purchaseRequest.sinumber;
  const initialDetail2 = {
    quantity: false,
    description: false,
    unitprice: false,
    currency: false,
    amount: false,
    wbs: false,
    wbsname: false,
  };

  const validationErrors = {
    details: [initialDetail2],
  };

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(validationErrors);

  const [formData, setFormData] = useState({
    prnumber: prNumber,
    prdate: "",
    submittedby: "",
    status: "",
    projectnumber: "",
    inScope: "",
    prtitle: "",
    vendor: "",
    materialgrp: "",
    quoteno: "",
    leadtime: "",
    requestedby: "",
    paymentterms: "",
    duedate: "",
    additionalcomment: "",
    details: [initialDetail],
    sino: "",
    externalContractor: externalContractor,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "prdate") {
      const newDate = new Date(value).toISOString().split("T")[0];

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: newDate,
      }));

      return;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      externalContractor,
    }));
  }, [externalContractor]);
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (isSupplierSelected === "yes") {
      if (
        !externalContractor.ContractorsName ||
        !externalContractor.ContractorsEmail
      ) {
        toast.error("Please fill in contractor's details");
        setLoading(false);
        return;
      }
      if (
        externalContractor.ContractorsEmail &&
        !emailRegex.test(externalContractor.ContractorsEmail)
      ) {
        toast.error("Please enter a valid Contractor's Email");
        setLoading(false);
        return;
      }
      if (
        externalContractor.SubContractorsEmail &&
        !emailRegex.test(externalContractor.SubContractorsEmail)
      ) {
        toast.error("Please enter a valid Sub-Contractor's Email");
        setLoading(false);
        return;
      }
    }
    try {
      if (filesToUpload.length > 0) {
        const uploadSuccess = await dispatch(
          editUploadFiles(prNumber, filesToUpload)
        );
        if (!uploadSuccess) {
          toast.error("File upload failed");
          setLoading(false);
          return;
        }
      }
      const updatedFormData = {
        ...formData,
        approvers: selectedApprovers,
      };

      await dispatch(performEditPurchaseRequest(updatedFormData, prNumber));
      toast.success("Purchase Request Edited Successfully");
      console.log("Form data submitted:", formData);
      navigate("/dashboard/pr-dashboard/");
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Something goes wrong.Please try again");
    }

    setLoading(false);
  };

  const [details, setDetails] = useState([initialDetail]);

  useEffect(() => {
    if (purchaseRequest) {
      const prData = purchaseRequest;
      if (purchaseRequest?.prdate) {
        setFormData((prevFormData) => ({
          ...prData,
          prdate: new Date(purchaseRequest?.prdate).toISOString(),
          materialgrp: prData?.materialgrp,
          details:
            purchaseRequest?.details?.length > 0
              ? purchaseRequest?.details
              : [initialDetail],
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prData,
          materialgrp: prData?.materialgrp,
          details:
            purchaseRequest?.details?.length > 0
              ? purchaseRequest?.details
              : [initialDetail],
        }));
      }

      if (prData.details && prData.details.length > 0) {
        setDetails(prData.details);
      } else {
        setDetails([initialDetail]);
      }
      if (prData.externalContractor) {
        setExternalContractor(prData.externalContractor);
        setIsSupplierSelected(
          prData.externalContractor.externalContractor ? "yes" : "no"
        );
      } else {
        setExternalContractor(initialContractorState);
        setIsSupplierSelected("no");
      }
    }
  }, [purchaseRequest, prNumber]);

  const handleSubmitForApproval = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setLoading(true);
    if (validateForm()) {
      setLoading(false);
      console.log(errorMessages);
      console.log(errors);
      return;
    }

    try {
      if (filesToUpload.length > 0) {
        const uploadSuccess = await dispatch(
          editUploadFiles(prNumber, filesToUpload)
        );
        if (!uploadSuccess) {
          toast.error("File upload failed");
          setLoading(false);
          return;
        }
      }
      const updatedFormData = {
        ...formData,
        prstatus: "Pending",
        approvers: selectedApprovers,
      };
      await dispatch(performEditPurchaseRequestWithApprover(updatedFormData));
      toast.success("Purchase Request Submitted Successfully for approval");
      setFormData([]);
      navigate("/dashboard/pr-dashboard");
    } catch (error) {
      toast.error("Error submitting form");
      console.error("Error submitting form:", error);
    }
    setLoading(false);
  };
  const handleDeleteAttachment = async (attachment) => {
    await dispatch(deleteFile(prNumber, attachment.name))
      .then(() => {
        toast.success("File deleted successfully");
        dispatch(fetchFiles(prNumber))
          .then((res) => {
            setFiles(res);
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
        toast.error("Failed to delete file");
      });
  };

  useEffect(() => {
    if (isSubmitted) {
      const updatedErrors = { ...errors };
      const updatedErrorMessages = {
        ...errorMessages,
        details: errorMessages.details
          ? [...errorMessages.details]
          : formData.details.map(() => ({})),
      };

      // Helper function to update error state
      const updateErrorState = (field, value, errorMessage) => {
        if (value !== undefined && value !== null && value !== "") {
          updatedErrors[field] = false;
          updatedErrorMessages[field] = "";
        } else if (formData[field] !== undefined) {
          updatedErrors[field] = true;
          updatedErrorMessages[field] = errorMessage;
        }
      };

      updateErrorState("prtitle", formData.prtitle, "PR Title is required.");
      updateErrorState(
        "projectnumber",
        formData.projectnumber,
        "Project Number is required."
      );
      updateErrorState("vendor", formData.vendor, "Vendor is required.");
      updateErrorState(
        "inScope",
        formData.inScope,
        "Within scope is required."
      );
      updateErrorState(
        "materialgrp",
        formData.materialgrp,
        "Material Group is required."
      );
      updateErrorState(
        "quoteno",
        formData.quoteno,
        "Quote Number is required."
      );
      updateErrorState("leadtime", formData.leadtime, "Lead Time is required.");
      updateErrorState(
        "requestedby",
        formData.requestedby,
        "Requested By is required."
      );
      updateErrorState(
        "paymentterms",
        formData.paymentterms,
        "Payment Terms are required."
      );
      updateErrorState("duedate", formData.duedate, "Due Date is required.");

      if (selectedApprovers !== undefined) {
        updateErrorState(
          "approver",
          selectedApprovers?.length > 0,
          "At least one approver must be selected."
        );
      }

      formData?.details?.forEach((item, index) => {
        if (!updatedErrorMessages?.details[index]) {
          updatedErrors.details[index] = {};
          updatedErrorMessages.details[index] = {};
        }

        const updateDetailErrorState = (field, value, errorMessage) => {
          if (value !== undefined && value !== null && value !== "") {
            if (field === "quantity" && parseFloat(value) <= 0) {
              updatedErrors.details[index][field] = true;
              updatedErrorMessages.details[index][field] =
                "Quantity must be greater than 0.";
            } else {
              updatedErrors.details[index][field] = false;
              updatedErrorMessages.details[index][field] = "";
            }
          } else if (item[field] !== undefined) {
            updatedErrors.details[index][field] = true;
            updatedErrorMessages.details[index][field] = errorMessage;
          }
        };

        updateDetailErrorState("amount", item.amount, "Amount is required.");
        updateDetailErrorState(
          "description",
          item.description,
          "Description is required."
        );
        updateDetailErrorState(
          "quantity",
          item.quantity,
          "Quantity is required."
        );
        updateDetailErrorState(
          "unitprice",
          item.unitprice,
          "Unit Price is required."
        );
        updateDetailErrorState(
          "currency",
          item.currency,
          "Currency is required."
        );
        updateDetailErrorState("wbs", item.wbs, "WBS is required.");
        updateDetailErrorState(
          "wbsname",
          item.wbsname,
          "WBS Name is required."
        );
      });

      if (externalContractor.externalContractor) {
        updateErrorState(
          "ContractorsName",
          externalContractor.ContractorsName,
          "Contractor's Name is required."
        );
        if (externalContractor.ContractorsEmail !== undefined) {
          updateErrorState(
            "ContractorsEmail",
            emailRegex.test(externalContractor.ContractorsEmail),
            "Valid Contractor's Email is required."
          );
        }
        if (
          externalContractor.SubContractorsEmail !== undefined &&
          externalContractor.SubContractorsEmail !== ""
        ) {
          updateErrorState(
            "SubContractorsEmail",
            emailRegex.test(externalContractor.SubContractorsEmail),
            "Valid Subcontractor's Email is required."
          );
        } else {
          updatedErrors.SubContractorsEmail = false;
          updatedErrorMessages.SubContractorsEmail = "";
        }
      }

      setErrors(updatedErrors);
      setErrorMessages(updatedErrorMessages);
    }
  }, [formData, externalContractor, selectedApprovers]);
  useEffect(() => {
    if (isSubmitted) {
      validateForm();
    }
  }, [isSubmitted, selectedApprovers]);
  const validateForm = () => {
    const initialDetail = {
      quantity: false,
      description: false,
      unitprice: false,
      currency: false,
      amount: false,
      wbs: false,
      wbsname: false,
    };
    const initialDetailMessages = {
      quantity: "",
      description: "",
      unitprice: "",
      currency: "",
      amount: "",
      wbs: "",
      wbsname: "",
    };

    const validationErrors = {
      details: formData.details.map(() => ({ ...initialDetail })),
    };

    const validationMessages = {
      details: formData.details.map(() => ({ ...initialDetailMessages })),
    };
    if (!formData.prtitle) {
      validationErrors.prtitle = true;
      validationMessages.prtitle = "PR Title is required.";
    }

    if (!formData.projectnumber) {
      validationErrors.projectnumber = true;
      validationMessages.projectnumber = "Project Number is required.";
    }

    if (!formData.vendor) {
      validationErrors.vendor = true;
      validationMessages.vendor = "Vendor is required.";
    }

    if (!formData.inScope) {
      validationErrors.inScope = true;
      validationMessages.inScope = "Within Scope is required.";
    }

    if (!formData.materialgrp) {
      validationErrors.materialgrp = true;
      validationMessages.materialgrp = "Material Group is required.";
    }

    if (!formData.quoteno) {
      validationErrors.quoteno = true;
      validationMessages.quoteno = "Quote Number is required.";
    }

    if (!formData.leadtime) {
      validationErrors.leadtime = true;
      validationMessages.leadtime = "Lead Time is required.";
    }

    if (!formData.requestedby) {
      validationErrors.requestedby = true;
      validationMessages.requestedby = "Requested By is required.";
    }

    if (!formData.paymentterms) {
      validationErrors.paymentterms = true;
      validationMessages.paymentterms = "Payment Terms are required.";
    }

    if (!formData.duedate) {
      validationErrors.duedate = true;
      validationMessages.duedate = "Due Date is required.";
    }

    if (selectedApprovers?.length === 0) {
      validationErrors.approver = true;
      validationMessages.approver = "At least one approver must be selected.";
    }

    validationErrors.details = formData.details.map((item, index) => {
      const detailErrors = {};
      const detailMessages = {};

      if (!item.amount) {
        detailErrors.amount = true;
        detailMessages.amount = "Amount is required.";
      }
      if (!item.description) {
        detailErrors.description = true;
        detailMessages.description = "Description is required.";
      }
      if (!item.quantity || parseFloat(item.quantity) <= 0) {
        detailErrors.quantity = true;
        detailMessages.quantity = "Quantity must be greater than 0.";
      }
      if (!item.unitprice) {
        detailErrors.unitprice = true;
        detailMessages.unitprice = "Unit Price is required.";
      }

      if (!item.currency) {
        detailErrors.currency = true;
        detailMessages.currency = "Currency is required.";
      }
      if (!item.wbs) {
        detailErrors.wbs = true;
        detailMessages.wbs = "WBS is required.";
      }

      validationMessages.details[index] = detailMessages;
      return detailErrors;
    });

    if (externalContractor.externalContractor) {
      if (!externalContractor.ContractorsName) {
        validationErrors.ContractorsName = true;
        validationMessages.ContractorsName = "Contractor's Name is required.";
      } else {
        validationErrors.ContractorsName = false;
      }

      if (
        !externalContractor.ContractorsEmail ||
        !emailRegex.test(externalContractor.ContractorsEmail)
      ) {
        validationErrors.ContractorsEmail = true;
        validationMessages.ContractorsEmail =
          "Valid Contractor's Email is required.";
      } else {
        validationErrors.ContractorsEmail = false;
      }

      if (
        externalContractor.SubContractorsEmail &&
        !emailRegex.test(externalContractor.SubContractorsEmail)
      ) {
        validationErrors.SubContractorsEmail = true;
        validationMessages.SubContractorsEmail =
          "Valid Subcontractor's Email is required.";
      } else {
        validationErrors.SubContractorsEmail = false;
      }
    }

    if (isSubmitted) {
      setErrors(validationErrors);
      setErrorMessages(validationMessages);
    }

    const hasErrors = Object.values(validationErrors).some((field) => {
      if (Array.isArray(field)) {
        return field.some((detailItem) =>
          Object.values(detailItem).some((error) => error === true)
        );
      }
      return field === true;
    });

    if (hasErrors && isSubmitted) {
      toast.error("Please fill all the required fields");
    }

    return hasErrors;
  };
  const hasErrorMessages = (messages) => {
    if (!messages) return false;

    if (typeof messages === "object") {
      return Object.values(messages).some((value) =>
        typeof value === "string"
          ? value.trim() !== ""
          : hasErrorMessages(value)
      );
    }

    return false;
  };

  return (
    <section className=" shadow-lg p-5  bg-white">
      {loading && (
        <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 backdrop-blur-md">
          <Loader />
        </div>
      )}
      <h1 className="text-2xl font-bold leading-6 text-slate-700 mb-8 text-center">
        Edit Visy Glass Purchase Requisition Request Form
      </h1>

      <div className=" flex gap-x-5 justify-between items-end">
        <FormDynamicValues
          prnumber={prNumber}
          handleInputChange={handleInputChange}
          prdate={formData.prdate}
          submittedby={formData.submittedby}
          status={formData.status}
        />
        {si ? (
          <Link to={`/dashboard/edit-si?prnumber=${prNumber}`}>
            <button className="bg-[#3bb7b6] rounded-md text-white px-5 py-2">
              View / Edit Site Instruction
            </button>
          </Link>
        ) : (
          <Link
            to={`/dashboard/initiate-site-instruction?prnumber=${prNumber}`}
          >
            <button className="bg-[#3bb7b6] rounded-md text-white px-5 py-2">
              Add Site Instruction
            </button>
          </Link>
        )}
      </div>

      <form
        onSubmit={handleFormSubmit}
        className="grid mt-5 grid-cols-12 gap-x-5"
      >
        <div className="col-span-3   flex flex-col gap-y-3">
          <AppTextInput
            value={formData?.prtitle}
            onChange={handleInputChange}
            name={"prtitle"}
            label={"PR TITLE"}
            isError={errors?.prtitle}
          />
          <AppTextInput
            value={formData?.projectnumber}
            onChange={handleInputChange}
            name={"projectnumber"}
            label={"PROJECT NO."}
            isError={errors?.projectnumber}
          />
          <AppTextInput
            value={formData?.vendor}
            onChange={handleInputChange}
            name={"vendor"}
            label={"VENDOR"}
            isError={errors?.vendor}
          />
          <div>
            <label
              className="block text-sm font-medium text-gray-900"
              htmlFor={"Scope"}
            >
              WITHIN SCOPE?
            </label>
            <select
              name="inScope"
              value={formData?.inScope}
              onChange={handleInputChange}
              className={`mt-1 ${
                errors?.inScope ? "border-red-600" : "border-gray-400"
              } bg-gray-200  rounded-md  border-2 px-2 py-2  text-[14px] w-full`}
            >
              <option value="">Select</option>
              <option value="In Scope">In Scope</option>
              <option value="Out of Scope">Out of Scope</option>
            </select>
          </div>
          <MaterialSelector
            error={errors?.materialgrp}
            value={formData?.materialgrp || ""}
            setFormData={setFormData}
          />
          <AppTextInput
            isError={errors?.quoteno}
            value={formData?.quoteno}
            onChange={handleInputChange}
            name={"quoteno"}
            label={"QUOTE NO"}
          />
          <AppTextInput
            isError={errors?.leadtime}
            value={formData?.leadtime}
            onChange={handleInputChange}
            name={"leadtime"}
            label={"LEAD TIME"}
          />
          <AppTextInput
            isError={errors?.requestedby}
            value={formData?.requestedby}
            onChange={handleInputChange}
            name={"requestedby"}
            label={"REQUESTED BY"}
          />
          <AppTextInput
            isError={errors?.paymentterms}
            value={formData?.paymentterms}
            onChange={handleInputChange}
            name={"paymentterms"}
            label={"PAYMENT TERMS"}
          />
          <AppDateInput
            isError={errors?.duedate}
            value={formData?.duedate}
            onChange={handleInputChange}
            name={"duedate"}
            label={"DUE DATE"}
          />
        </div>
        <div className="col-span-9 mt-5 ">
          <DetailsFormTable
            errors={errors?.details}
            formData={formData}
            defaultData={formData.details}
            setDetails={setDetails}
            setFormData={setFormData}
            details={formData.details}
            handleInputChange={handleInputChange}
          />
          <div className="mt-5">
            <h3 className="block text-sm font-medium text-gray-900 mb-4">
              ATTACHMENTS
            </h3>
            {files.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        File Name
                      </th>
                      <th className="px-6 py-3 bg-gray-50 w-20"></th>
                      <th className="px-6 py-3 bg-gray-50 w-20"></th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {files.map((attachment, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {attachment.name}
                        </td>
                        <td className="px-3 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <a
                            href={attachment.fileUrl}
                            target="_blank"
                            download
                            rel="noopener noreferrer"
                            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-[#194099] hover:bg-[#305abc] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            <IoMdEye className="mr-2 h-4 w-4 " />
                            View
                          </a>
                        </td>
                        <td className="px-3 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button
                            type="button"
                            onClick={() => handleDeleteAttachment(attachment)}
                            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            <MdDelete className="mr-2 h-4 w-4" />
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p></p>
            )}
            {files.length < 3 && (
              <EditDropZone
                files={filesToUpload}
                setFilesToUpload={setFilesToUpload}
                noOfFiles={3 - files.length}
              />
            )}
          </div>
          <ExternalContractor
            externalContractor={externalContractor}
            setExternalContractor={setExternalContractor}
            isSupplierSelected={isSupplierSelected}
            setIsSupplierSelected={setIsSupplierSelected}
            errors={errors}
          />
          <div className="flex ">
            <button
              className="bg-[#194099] shadow-md rounded-md text-white px-5 py-2 mt-5"
              type="submit"
            >
              Save & Exit
            </button>
          </div>

          <ApproverSelector
            isError={errors?.approver}
            onApproverSelection={handleApproverSelection}
          />
          <button
            className="bg-[#3bb75a] shadow-md rounded-md text-white px-5 py-2 mt-4 hover:bg-[#2b8040]"
            type="button"
            onClick={handleSubmitForApproval}
          >
            Submit for Approval
          </button>
          <ManagerOnlyInput />
          {isSubmitted && errorMessages && hasErrorMessages(errorMessages) && (
            <div className="mt-4 p-4 border border-red-700 bg-red-100 text-red-700 rounded">
              <h3 className="text-md font-semibold text-red-700 mb-2">
                Please check the following :
              </h3>
              <ul className="list-disc list-inside text-sm">
                {Object.entries(errorMessages).map(([key, error]) =>
                  typeof error === "string" && error ? (
                    <li key={key}>{error}</li>
                  ) : null
                )}
                {errorMessages.details && (
                  <>
                    {[
                      ...new Set(
                        errorMessages.details.flatMap((detailError) =>
                          Object.values(detailError).filter(Boolean)
                        )
                      ),
                    ].map((message, index) => (
                      <li key={`detail-error-${index}`}>{message}</li>
                    ))}
                  </>
                )}
              </ul>
            </div>
          )}
        </div>
      </form>
    </section>
  );
};

export default EditPurchaseRequestForm;
