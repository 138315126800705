import { createSlice } from "@reduxjs/toolkit";
import{  fetchPendingAppRequestSi} from '../action'
// Create the slice
const initialState = {
    pendingRequestsSi: [],
    error: null,
  };
const pendingRequestsSiSlice = createSlice({
    name: "approverPendingRequestSi",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchPendingAppRequestSi.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchPendingAppRequestSi.fulfilled, (state, action) => {
          state.loading = false;
          state.pendingRequestsSi = action.payload;
          state.error = null;
        })
        .addCase(fetchPendingAppRequestSi.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        });
    },
  });
  

  
  // Export the reducer
  export default pendingRequestsSiSlice.reducer;