import React, { useEffect, useState } from "react";
import AppSelectInput from "../../../UI/Input/AppSelectInput";
import { fetchApprovers } from "../../../../redux/action";
import { useSelector, useDispatch } from "react-redux";

const SIApproverSelector = ({ onApproverSelection, isError }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchApprovers());
  }, [dispatch]);

  const defaultApproverList = useSelector((state) => {
    return state.approver.approvers.filter(
      (approver) => approver.email !== "admin@brrposiworkflow.com"
    );
  });

  const [selectedApprovers, setSelectedApprovers] = useState([]);

  useEffect(() => {
    onApproverSelection(selectedApprovers);
  }, [selectedApprovers, onApproverSelection]);

  const handleApproverChange = (index, selectedEmail) => {
    const selectedApprover = defaultApproverList.find(
      (approver) => approver.email === selectedEmail
    );

    const newApprovers = [...selectedApprovers];
    newApprovers[index] = {
      id: selectedApprover._id, // Change this to the actual id
      name: selectedApprover.username, // Change this to the actual name
      email: selectedEmail,
    };

    setSelectedApprovers(newApprovers);
  };

  return (
    <div className="mt-10">
      <p className="font-bold">Approvals</p>
      <div className="border border-gray-300 flex flex-col gap-y-2 rounded-md w-full p-5 mt-1">
        {Array.from({ length: 3 }).map((_, index) => (
          <AppSelectInput
            isError={isError && index === 0 ? true : false}
            key={index}
            value={selectedApprovers[index]?.email || ""}
            data={defaultApproverList.filter(
              (approver) =>
                !selectedApprovers
                  .slice(0, index)
                  .find((selApprover) => selApprover.email === approver.email)
            )}
            getter={"email"}
            name={`approver${index + 1}`}
            onChange={(e) => {
              if (e.target.value !== "Select") {
                handleApproverChange(index, e.target.value);
              } else {
                handleApproverChange(index, "");
              }
            }}
            isDisabled={index === 0 ? false : !selectedApprovers[index - 1]}
            label={`Approver ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default SIApproverSelector;
