import React, { useState } from "react";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import "./DropZone.css";
const EditDropZone = ({ files, setFilesToUpload, noOfFiles }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const handleAddFile = (fileWithMeta) => {
    setFilesToUpload((prevFiles) => [...prevFiles, fileWithMeta]);
  };

  const handleRemoveFile = (fileWithMeta) => {
    const updatedFiles = files.filter(
      (file) => file.meta.uuid !== fileWithMeta.meta.uuid
    );
    setFilesToUpload(updatedFiles);
  };
  const validate = (fileWithMeta) => {
    if (fileWithMeta.file.size > 5 * 1024 * 1024) {
      fileWithMeta.remove();
      setErrorMessage("File size exceeds 5MB. Please upload a smaller file.");
      return "File size exceeds 5MB. Please upload a smaller file.";
    }
    setErrorMessage("");
    return null;
  };
  return (
    <div>
      <Dropzone
        onChangeStatus={(fileWithMeta, status) => {
          if (status === "done") handleAddFile(fileWithMeta);
          if (status === "removed") handleRemoveFile(fileWithMeta);
        }}
        onSubmit={() => {}}
        maxFiles={noOfFiles}
        inputContent="Upload Here"
        inputWithFilesContent={(files) => ` Add more`}
        submitButtonContent={null}
        classNames={{
          dropzone: "dropzone",
          dropzoneActive: "dropzoneActive",
          inputLabel: "dropzone-input-content",
          inputLabelWithFiles: "dropzone-input-with-files-content",
          submitButton: "dropzone-submit-button",
          preview: "preview",
        }}
        validate={validate}
        autoUpload={false}
      />
      {errorMessage && (
        <p className="text-red-400  text-sm mx-2 mt-2">{errorMessage}</p>
      )}
    </div>
  );
};

export default EditDropZone;
