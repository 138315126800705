import { combineReducers } from "redux";

import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage/session";
import { persistReducer, persistStore } from "redux-persist";
import authReducer from "./reducers/auth";
import thunkMiddleware from "redux-thunk";
import purchaseCreateSlice from "./reducers/purchaseCreate";
import purchaseRequestSlice from "./reducers/purchaseRequestId";
import approversSlice from "./reducers/approvers";
import pendingRequestsSlice from "./reducers/approverReqPr";
import pendingRequestsSiSlice from "./reducers/approverReqSi";
import materialsReducer from "./reducers/Materials";
import prnumberSlice from "./reducers/prnumber";
import wbs from "./reducers/wbs";
import singlePurchaseReq from "./reducers/singlePurchaseReq";
import sinumberSlice from "./reducers/sinumber";
import siteInstructionSlice from "./reducers/siteInstructionCreate"
import singleSiRequestSlice from "./reducers/singleSireq"
import singlePendingSiRequestSlice from "./reducers/singlePendingSi";
const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  purchaseCreate: purchaseCreateSlice,
  // purchaseRequestsId:purchaseRequestsReducer,
  approver: approversSlice,
  approverPendingRequest: pendingRequestsSlice,
  approverPendingRequestSi:pendingRequestsSiSlice,
  materials: materialsReducer,
  wbs: wbs,
  prnumber: prnumberSlice,
  sinumber:sinumberSlice,
  purchaseRequests: purchaseRequestSlice,
  signlePr: singlePurchaseReq,
  singleSi: singleSiRequestSlice ,
  siteInstruction:siteInstructionSlice,
  singlePendingSi:singlePendingSiRequestSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunkMiddleware],
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
