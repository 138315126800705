import React, { useState } from "react";
import UserActionModal from "../../UI/Modal/UserActionModal";
import useSwr from "swr";
import axiosInstance from "../../utils/axiosInstance";
import { BiEditAlt } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import Loader from "../../UI/Loader";
import MyDataGrid from "../../UI/MyDataGrid";
import { toast } from "react-hot-toast";
import Pagination from "../../UI/Pagination";
import AskDeleteUserModal from "../../UI/Modal/AskDeleteUserModal";
import { TiUserAdd } from "react-icons/ti";
const ManageUsers = () => {
  const [isCreateUserModalOpen, setCreateUserModal] = useState(false);
  const [isEditedUserModal, setEditedUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isAskDeleteModalOpen, setAskDeleteModalOpen] = useState(false);
  const [triggerLoad, setTriggerLoad] = useState(false);
  const [pageNo, setPageNo] = useState(1);

  async function fetchAllUser() {
    try {
      const response = await axiosInstance.get(`/users?pageNo=${pageNo}`);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  const { data: users, isLoading } = useSwr(
    ["all-users", triggerLoad, pageNo],
    fetchAllUser
  );

  async function handleDelete() {
    try {
      const token = localStorage.getItem("jwttoken");

      const API = `${process.env.REACT_APP_SERVER_URL}/deleteUser?userId=${selectedUser._id}`;

      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };

      const res = await fetch(API, requestOptions);

      if (res.ok) {
        toast.success("User Deleted Successfully");
        setTriggerLoad(!triggerLoad);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something goes wrong");
    }

    setAskDeleteModalOpen(false);
  }

  const columns = [
    {
      field: "username",
      headerName: "Full Name",
      width: 250,
    },
    {
      field: "email",
      headerName: "Email",
      width: 300,
    },
    {
      field: "role",
      headerName: "Role",
      width: 100,
    },
    {
      field: "_id",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        const id = params.value;

        const user = users?.users?.filter((user) => user?._id === id)[0];

        return (
          <div className="flex gap-x-4 items-center">
            <button
              onClick={() => {
                setSelectedUser(user);
                setEditedUserModal(true);
              }}
            >
              <BiEditAlt className="text-blue-800 text-2xl" />
            </button>
            <button
              onClick={() => {
                setSelectedUser(user);
                setAskDeleteModalOpen(true);
              }}
            >
              <AiOutlineDelete className="text-red-600 text-2xl" />
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="h-full bg-white p-4">
      <main className="py-2 px-4 sm:px-8">
        <button
          onClick={() => setCreateUserModal(true)}
          className="bg-blue-900 mb-2 text-white px-5 py-2 rounded-md text-sm hover:shadow transition duration-200 flex  items-center gap-1"
        >
          Create User <TiUserAdd />
        </button>
        <div className="w-full">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <MyDataGrid
                idKey="_id"
                bgColor={"#1e3a8a"}
                rows={users.users}
                columns={columns}
              />
              <div className="flex justify-center">
                <Pagination
                  totalItem={users?.totalCount}
                  setPageNo={setPageNo}
                />
              </div>
            </>
          )}
        </div>
      </main>
      {isCreateUserModalOpen && (
        <UserActionModal
          isOpen={isCreateUserModalOpen}
          setOpen={setCreateUserModal}
          isNew={true}
          user={null}
          setTriggerLoad={setTriggerLoad}
        />
      )}
      {isEditedUserModal && (
        <UserActionModal
          isOpen={isEditedUserModal}
          setOpen={setEditedUserModal}
          isNew={false}
          user={selectedUser}
          setTriggerLoad={setTriggerLoad}
        />
      )}
      {isAskDeleteModalOpen && (
        <AskDeleteUserModal
          isOpen={isAskDeleteModalOpen}
          setOpen={setAskDeleteModalOpen}
          handleDeleteUser={handleDelete}
        />
      )}
    </div>
  );
};

export default ManageUsers;
