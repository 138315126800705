import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { useMyContext } from "../../context/storeApproverValue";

const Dashboard = () => {
  const navigate = useNavigate();

  const role = useSelector((state) => state.auth.role);

  const { toreview, prnumber, toreviewSi, toview } = useMyContext();

  useEffect(() => {
    if (toreview && prnumber && role === "approver") {
      navigate(`/dashboard/review-pr?prnumber=${prnumber}`);
      return;
    }
    if (
      toview &&
      prnumber &&
      (role === "approver" || role === "user" || role === "admin")
    ) {
      navigate(`/dashboard/view-pr?prnumber=${prnumber}`);
      return;
    }

    if (toreviewSi && prnumber && role === "approver") {
      navigate(`/dashboard/review-si?prnumber=${prnumber}`);
      return;
    }

    if (!toreview && !prnumber) {
      if (role === "") return;

      if (role === "user") {
        navigate("/dashboard/pr-dashboard");
        return;
      }

      if (role === "approver") {
        navigate("/dashboard/pr-approvals");
        return;
      }

      if (role === "admin") {
        navigate("/dashboard/pr-dashboard");
        return;
      }
    }
  }, [toreview, prnumber]);

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default Dashboard;
