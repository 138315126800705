import {generatePrNumber} from '../action'
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    prnumber: "",
    loading: false,
    error: null,
  };
  
  const prnumberSlice = createSlice({
    name: 'prnumber',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(generatePrNumber.pending, (state) => {
          state.loading = true;
        })
        .addCase(generatePrNumber.fulfilled, (state, action) => {
          state.loading = false;
          state.prnumber = action.payload;
          state.error = null;
        })
        .addCase(generatePrNumber.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        });
    },
  });
  export default prnumberSlice.reducer;  