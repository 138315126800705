import React from "react";
import { FaFileExport, FaMagnifyingGlass } from "react-icons/fa6";
import { IoCloseSharp } from "react-icons/io5";

const SIFilters = ({
  handleInputChange,
  filter,
  handleSearch,
  handleClear,
  isShowClearBtn,
  handleExport,
}) => {
  return (
    <div className="flex justify-between mb-3">
      <div className="flex gap-x-5 items-end">
        <div>
          <label className="text-[12px]">Show Records</label>
          <select
            name="showRecords"
            onChange={handleInputChange}
            value={filter.showRecords}
            className="bg-gray-200 border mt-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-1.5"
          >
            <option>30 Days</option>
            <option>60 Days</option>
            <option>90 Days</option>
          </select>
        </div>
        <div>
          <label className="text-[12px]">Status</label>
          <select
            name="status"
            onChange={handleInputChange}
            value={filter.status}
            className="bg-gray-200 border mt-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-1.5"
          >
            <option>All</option>
            <option>Draft</option>
            <option>Pending</option>
            <option>Approved</option>
            <option>Closed</option>
            <option>Cancelled</option>
          </select>
        </div>
        <div>
          <label className="text-[12px]">In Scope?</label>
          <select
            name="inScope"
            onChange={handleInputChange}
            value={filter.inScope}
            className="bg-gray-200 border mt-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-1.5"
          >
            <option>All</option>
            <option>Yes</option>
            <option>No</option>
          </select>
        </div>
      </div>
      <div className="flex gap-x-5 items-end">
        <div>
          <label className="text-[12px]">Search By SI No.</label>
          <input
            name="search"
            onChange={handleInputChange}
            value={filter.search}
            className="bg-gray-200 border mt-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-1.5"
          />
        </div>
        <div>
          <label className="text-[12px]">From</label>
          <input
            name="from"
            onChange={handleInputChange}
            value={filter.from}
            type="date"
            className="bg-gray-200 border mt-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-1.5"
          />
        </div>
        <div>
          <label className="text-[12px]">To</label>
          <input
            name="to"
            onChange={handleInputChange}
            value={filter.to}
            type="date"
            className="bg-gray-200 border mt-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-1.5"
          />
        </div>

        <button
          onClick={handleSearch}
          className="bg-[#1e3a8a] flex gap-x-2 items-center text-white text-[14px] rounded-md px-2 py-2"
        >
          <p>Search</p> <FaMagnifyingGlass />
        </button>
        {isShowClearBtn && (
          <button
            onClick={handleClear}
            className="bg-[#1e3a8a] flex gap-x-2 items-center text-white text-[14px] rounded-md px-2 py-2"
          >
            <p>Clear</p> <IoCloseSharp />
          </button>
        )}
        <button
          onClick={handleExport}
          className="bg-[#1e3a8a] flex gap-x-2 items-center text-white text-[14px] rounded-md px-2 py-2"
        >
          <p>Export</p> <FaFileExport />
        </button>
      </div>
    </div>
  );
};

export default SIFilters;
