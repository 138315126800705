import axios from "axios";
import axiosInstance from "../components/utils/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  purchaseRequestStart,
  purchaseRequestSuccess,
  purchaseRequestFailure,
} from "./reducers/purchaseCreate";
import {
  siteInstructionStart,
  siteInstructionSuccess,
  siteInstructionFailure,
} from "./reducers/siteInstructionCreate";
// Action Types
const LOGIN_REQUEST = "auth/loginRequest";
const LOGIN_SUCCESS = "auth/loginSuccess";
const LOGIN_FAILURE = "auth/loginFailure";
const LOGOUT = "auth/logOut";

export const loginRequest = () => {
  return {
    type: LOGIN_REQUEST,
  };
};
export const loginError = (error) => {
  return {
    type: LOGIN_FAILURE,
    payload: {
      error,
    },
  };
};
export const loginComplete = (user_id, user_email, role, username) => {
  return {
    type: LOGIN_SUCCESS,
    payload: {
      user_id,
      user_email,
      role,
      username,
    },
  };
};
export const login = (email, password) => {
  return async (dispatch) => {
    dispatch(loginRequest());

    try {
      const response = await axiosInstance.post("/login", {
        email,
        password,
      });

      if (response.status === 200) {
        const token = response.data.token;
        const { _id: userId, email, role, username } = response.data.user;

        localStorage.setItem("jwttoken", token);

        dispatch(loginComplete(userId, email, role, username));
        return "success";
      } else {
        dispatch(loginError("An error occurred. Please try again later."));
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(loginError("Unauthorized"));
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        dispatch(loginError(error.response.data.error));
      } else {
        dispatch(loginError("An error occurred. Please try again later."));
      }
      return "error";
    }
  };
};
export const logout = () => {
  return async (dispatch) => {
    localStorage.removeItem("jwtToken");
    dispatch({
      type: LOGOUT,
    });
  };
};
export const performPurchaseRequest = (formData) => async (dispatch) => {
  dispatch(purchaseRequestStart());
  try {
    const response = await axiosInstance.post("/purchase-request", formData);
    const savedPurchaseRequest = response.data;
    dispatch(purchaseRequestSuccess(savedPurchaseRequest));

    return savedPurchaseRequest.prnumber;
  } catch (error) {
    console.error(error);
    dispatch(purchaseRequestFailure("Failed to create purchase request."));
  }
};

export const performPurchaseRequestWithAppr =
  (formData) => async (dispatch) => {
    dispatch(purchaseRequestStart());
    try {
      const response = await axiosInstance.post(
        "/purchase-request-with-approver",
        formData
      );
      const savedPurchaseRequest = response.data;
      dispatch(purchaseRequestSuccess(savedPurchaseRequest));
      const prnumber = savedPurchaseRequest.prnumber;
      console.log(prnumber);
      return prnumber;
    } catch (error) {
      console.error(error);
      dispatch(purchaseRequestFailure("Failed to create purchase request."));
    }
  };

export const fetchPurchaseRequests = createAsyncThunk(
  "purchaseRequests/fetchPurchaseRequests",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/purchase-request");
      const purchaseRequests = response.data;

      return purchaseRequests;
    } catch (error) {
      return rejectWithValue("Failed to fetch purchase requests.");
    }
  }
);
export const fetchSiRequests = createAsyncThunk(
  "purchaseRequests/fetchSiRequests",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/si-request");
      const purchaseRequests = response.data;

      return purchaseRequests;
    } catch (error) {
      return rejectWithValue("Failed to fetch purchase requests.");
    }
  }
);

export const fetchApprovers = createAsyncThunk(
  "approvers/fetchApprovers",
  async () => {
    try {
      const response = await axiosInstance.get("/approvers");

      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);

export const fetchPendingAppRequest = createAsyncThunk(
  "pendingRequests/fetchPendingAppRequest",
  async (paginationParams) => {
    try {
      const response = await axiosInstance.get(
        "/purchase-request/pending-requests",
        {
          params: paginationParams,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  }
);
export const approveOrRejectRequest = createAsyncThunk(
  "approvers/purchase-request/review/:prnumber",
  async (data) => {
    try {
      const response = await axiosInstance.patch(
        "/purchase-request/review/:prnumber",
        data
      );
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);
//si approve and reject
export const fetchPendingAppRequestSi = createAsyncThunk(
  "pendingRequests/get-pending-si",
  async (paginationParams) => {
    try {
      const response = await axiosInstance.get("/get-pending-si", {
        params: paginationParams,
      });
      // console.log(response.data)
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

export const approveOrRejectRequestSi = createAsyncThunk(
  "approvers/site-instruction/review/:prnumber",
  async (data) => {
    try {
      const response = await axiosInstance.patch(
        "/site-instruction/review/:prnumber",
        data
      );
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);

export const fetchMaterials = createAsyncThunk("materials/fetch", async () => {
  try {
    const response = await axiosInstance.get("/materials");
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const fetchWbs = createAsyncThunk("wbs/fetch", async () => {
  try {
    const response = await axiosInstance.get("/wbs");
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const generatePrNumber = createAsyncThunk(
  "pr/generatePrNumber",
  async () => {
    try {
      const response = await axiosInstance.get("/generate-pr-number");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const generaterSiNumber = createAsyncThunk(
  "pr/generaterSiNumber",
  async () => {
    try {
      const response = await axiosInstance.get("/generate-si-number");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getPrById = createAsyncThunk(
  "pr/purchase-requests/:prnumber",
  async (prNumber) => {
    // console.log(prNumber);
    try {
      const response = await axiosInstance.get(
        `/purchase-requests/${prNumber}`
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const performEditPurchaseRequest =
  (formData, prnumber) => async (dispatch) => {
    try {
      const response = await axiosInstance.patch(
        `/update-pr/${prnumber}`,
        formData
      );
      // console.log(response);
      const savedPurchaseRequest = response.data;
      // console.log("editted");
      return savedPurchaseRequest.prnumber;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
export const performEditPurchaseRequestWithApprover =
  (formData) => async (dispatch) => {
    try {
      const prnumber = formData.prnumber;
      const response = await axiosInstance.patch(
        `/update-pr-approver/${prnumber}`,
        formData
      );
      //  console.log(response);
      const savedPurchaseRequest = response.data;
      return savedPurchaseRequest.prnumber;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
export const performEditSiWithApprover = (formData) => async (dispatch) => {
  try {
    const prnumber = formData.prnumber;
    const response = await axiosInstance.patch(
      `/update-si-approver/${prnumber}`,
      formData
    );
    // console.log(response);
    const savedPurchaseRequest = response.data;
    // console.log("edited");
    return savedPurchaseRequest.prnumber;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

//siteinstruction create
export const performSiteInstruction = (formData) => async (dispatch) => {
  dispatch(siteInstructionStart());
  try {
    const response = await axiosInstance.post("/site-instruction", formData); // Update the API endpoint
    const savedSiteInstruction = response.data;
    dispatch(siteInstructionSuccess(savedSiteInstruction));
    return savedSiteInstruction.sinumber;
  } catch (error) {
    console.error(error);
    dispatch(siteInstructionFailure("Failed to create site instruction."));
  }
};

export const performSiteInstructionWithAppr =
  (formData) => async (dispatch) => {
    dispatch(siteInstructionStart());
    try {
      const response = await axiosInstance.post(
        "/site-instruction-with-approver",
        formData
      );
      const savedPurchaseRequest = response.data;
      dispatch(siteInstructionSuccess(savedPurchaseRequest));
      const prnumber = savedPurchaseRequest.prnumber;
      // console.log(prnumber);
      return prnumber;
    } catch (error) {
      console.error(error);
      dispatch(siteInstructionFailure("Failed to create purchase request."));
    }
  };

export const getSiById = createAsyncThunk(
  "si/site-instruction/:prnumber",
  async (prNumber) => {
    // console.log(prNumber);
    try {
      const response = await axiosInstance.get(`/site-instruction/${prNumber}`);
      // console.log(response);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getpendingSiById = createAsyncThunk(
  "si/get-pending-si/:prnumber",
  async (prNumber) => {
    // console.log(prNumber);
    try {
      const response = await axiosInstance.get(`/get-pending-si/${prNumber}`);

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const performEditSiRequest = (formData) => async (dispatch) => {
  try {
    const prnumber = formData.prnumber;
    const response = await axiosInstance.patch(
      `/update-si/${prnumber}`,
      formData
    );

    // console.log(response);

    const savedPurchaseRequest = response.data;
    return savedPurchaseRequest.prnumber;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const downloadPrPdf = (prnumber) => async () => {
  const API = `${process.env.REACT_APP_SERVER_URL}/pr-pdf?prnumber=${prnumber}`;

  try {
    const response = await axios.get(API, { responseType: "blob" });

    const blob = response.data;
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `${prnumber}.pdf`;
    link.click();

    URL.revokeObjectURL(url);

    // toast.success('Pdf Download Started');
  } catch (error) {
    console.error(error);
    // toast.error('Something went wrong');
  }
};

// export excel action methods
export const exportPrData = (Data) => async (dispatch) => {
  try {
    const { fromDate, toDate } = Data;

    const response = await axiosInstance.get(
      `/purchase-request-toExport?fromDate=${fromDate}&toDate=${toDate}`
    );
    // console.log(response);
    const prToExport = response.data;
    return prToExport;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const exportSIData = (Data) => async (dispatch) => {
  try {
    const { fromDate, toDate } = Data;

    const response = await axiosInstance.get(
      `/si-request-toExport?fromDate=${fromDate}&toDate=${toDate}`
    );
    const siToExport = response.data;
    return siToExport;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

//upload and get files from firebase action methods

//upload files action method

export const uploadFiles = (prNumber, files) => async (dispatch) => {
  try {
    const formData = new FormData();
    files.forEach((fileObj, index) => {
      formData.append(`files`, fileObj.file, fileObj.file.name);
    });
    const response = await axiosInstance.post(
      `/uploadFile/${prNumber}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return {
      success: true,
      error: {
        message: response.data.error,
      },
    };
  } catch (error) {
    return {
      success: false,
      error: {
        status: error.response.status,
        message: error.response.data.error,
      },
    };
  }
};
export const fetchFiles = (prNumber) => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`/getFile/${prNumber}`);
    const { fileData } = response.data;
    return fileData;
  } catch (error) {
    console.error("Error fetching files:", error);
    throw error;
  }
};
export const editUploadFiles = (prNumber, files) => async (dispatch) => {
  try {
    const formData = new FormData();
    files.forEach((fileObj, index) => {
      formData.append(`files`, fileObj.file, fileObj.file.name);
    });
    console.log(formData);
    const response = await axiosInstance.post(
      `/edit-upload/${prNumber}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data.fileUrls;
  } catch (error) {
    console.error("Error editing/uploading files:", error);
    throw error;
  }
};
export const deleteFile = (prNumber, fileName) => async (dispatch) => {
  try {
    const response = await axiosInstance.delete(
      `/files/${prNumber}/${fileName}`
    );
    return response.data.message;
  } catch (error) {
    console.error(`Error deleting file ${fileName}:`, error);
    throw error;
  }
};

export const deleteFolder = (prNumber, fileName) => async (dispatch) => {
  try {
    const response = await axiosInstance.delete(`/delete-folder/${prNumber}`);
    return response.data.message;
  } catch (error) {
    console.error(`Error deleting file ${fileName}:`, error);
    throw error;
  }
};
export const createOrUpdateCommercialEmail =
  (emailData) => async (dispatch) => {
    try {
      const response = await axiosInstance.post("/commercial-email", emailData);
      const updatedEmail = response.data;
      return updatedEmail;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
export const getCommercialEmail = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get("/commercial-email");
    const commercialEmail = response.data;

    return commercialEmail;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const createOrUpdateContractorEmail =
  (emailData) => async (dispatch) => {
    try {
      const response = await axiosInstance.post("/contractor-email", emailData);
      const updatedEmail = response.data;
      return updatedEmail;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
export const getContractorEmail = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get("/contractor-email");
    const contractorEmail = response.data;
    return contractorEmail;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
