import React, { useEffect, useState } from "react";
import Modal from "../../UI/Modal";
import { fetchFiles, getPrById } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../UI/Loader";
import { FaDownload } from "react-icons/fa";
const ViewPr = ({ isOpen, setOpen, prNumber }) => {
  const [attachments, setAttachments] = useState([]);
  const dispatch = useDispatch();

  const purchaseRequest = useSelector(
    (state) => state.signlePr.purchaseRequests
  );

  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    dispatch(getPrById(prNumber));
    dispatch(fetchFiles(prNumber))
      .then((res) => {
        setAttachments(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [dispatch]);

  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);

    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    let totalAmount = 0;
    if (purchaseRequest?.details?.length > 0) {
      for (let i = 0; i < purchaseRequest?.details?.length; i++) {
        totalAmount += parseFloat(purchaseRequest?.details[i].amount);
      }

      if (totalAmount > 0) {
        const finaltotalAmount = totalAmount.toFixed(2);
        setTotalAmount(finaltotalAmount);
      } else setTotalAmount(0);
    }
  }, [purchaseRequest]);

  const date = new Date(purchaseRequest?.prdate);

  const val = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

  return (
    <Modal isScroll={true} isOpen={isOpen} onClose={() => setOpen(false)}>
      {!purchaseRequest ? (
        <Loader></Loader>
      ) : (
        <div className="grid grid-cols-12 gap-x-5 items-start">
          <div className="col-span-7">
            <div className="flex gap-x-2 items-center">
              <p className="font-bold text-[#1e3a8a] text-xl">
                Purchase Request Details
              </p>
            </div>

            <div className="mt-5 text-[14px] flex flex-col gap-y-2 text-gray-600">
              <p>PR No. : {purchaseRequest.prnumber}</p>
              <p>PR Title : {purchaseRequest.prtitle}</p>
              <p>PR Date : {val}</p>
              <p>
                PR Status :{" "}
                <span
                  className={` ${
                    purchaseRequest?.prstatus === "Pending"
                      ? "text-orange-700"
                      : purchaseRequest?.prstatus === "Approved"
                      ? "text-green-600"
                      : purchaseRequest?.status === "Rejected"
                      ? "text-gray-800"
                      : purchaseRequest?.status === "Draft" && "text-orange-800"
                  } `}
                >
                  {purchaseRequest?.prstatus}
                </span>
              </p>
              {purchaseRequest?.sinumber ? (
                <p>Linked SI No. : {purchaseRequest.sinumber}</p>
              ) : (
                <p>Linked SI No. : N/A</p>
              )}
              <p>
                Purchase Order No :{purchaseRequest?.managerPurchaseOrderNumber}
              </p>
              <p>
                Purchase Order Date :
                {formatDate(purchaseRequest?.managerPurchaseOrderDate)}
              </p>
            </div>

            <div className="mt-5 text-[14px] flex flex-col gap-y-2 text-gray-600">
              <p className="font-medium text-base text-black">
                Purchase Information
              </p>
              <p>Vendor : {purchaseRequest.vendor}</p>
              <p>Material Group : {purchaseRequest.materialgrp}</p>
              <p>Lead Time : {purchaseRequest.leadtime}</p>
              <p>Requested By : {purchaseRequest.requestedby}</p>
              <p>Payment Terms : {purchaseRequest.paymentterms}</p>
              <p>Due Date : {formatDate(purchaseRequest?.duedate)}</p>
            </div>
          </div>
          <div className="col-span-5">
            <div className="">
              <p className="font-bold text-[#1e3a8a] text-xl mb-5">Approvers</p>
              {purchaseRequest?.approvers?.map((item, i) => (
                <div className="mt-5" key={i}>
                  <div className="flex gap-x-3 items-center">
                    <p>Approver {i + 1}:</p>
                    <p
                      className={` ${
                        item?.status === "Approved"
                          ? "text-green-600"
                          : item?.status === "Pending"
                          ? "text-orange-700"
                          : item?.status === "Rejected" && "text-gray-800"
                      } `}
                    >
                      {item?.status}
                    </p>
                  </div>
                  <div className="mt-2 text-[14px] flex flex-col gap-y-2 text-gray-600">
                    <p>Name : {item.name}</p>
                    <p>Email: {item.email}</p>
                    <p>Comments : {item.comments}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-span-12">
            {purchaseRequest?.externalContractor?.externalContractor && (
              <div className="mt-5 text-[14px] flex flex-col gap-y-2 text-gray-600">
                <h3 className="font-semibold text-lg text-[#1e3a8a] mb-2">
                  External Contractors
                </h3>

                <p>
                  Contractors Name:{" "}
                  {purchaseRequest.externalContractor?.ContractorsName}
                </p>
                <p>
                  Contractors Email:{" "}
                  {purchaseRequest.externalContractor?.ContractorsEmail}
                </p>
                <p>
                  Sub-Contractors Email:{" "}
                  {purchaseRequest.externalContractor?.SubContractorsEmail}
                </p>
              </div>
            )}
          </div>
          {/* Attachments  */}
          <div className="mt-5 col-span-12">
            <h3 className="font-semibold text-xl text-[#1e3a8a] mb-4">
              {" "}
              Attachments
            </h3>
            {attachments.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        File Name
                      </th>
                      <th className="px-6 py-3 bg-gray-50"></th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {attachments.map((attachment, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {attachment.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <a
                            href={attachment.fileUrl}
                            download
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-[#194099] hover:bg-[#305abc] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            <FaDownload className="mr-2 h-4 w-4" />
                            Download
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>No attachments available</p>
            )}
          </div>
          <div className="col-span-12">
            <p className="font-bold text-[#1e3a8a] text-xl mt-5 mb-5">
              Other Details
            </p>
            <table className="w-full mt-5 border-collapse border border-gray-300 shadow-md text-sm">
              <thead>
                <tr>
                  <th className="border border-gray-300 p-2 font-semibold bg-[#1e3a8a] text-white">
                    Quantity
                  </th>
                  <th className="border border-gray-300 p-2 font-semibold bg-[#1e3a8a] text-white">
                    Description
                  </th>
                  <th className="border border-gray-300 p-2 font-semibold bg-[#1e3a8a] text-white">
                    Unit Price
                  </th>
                  <th className="border border-gray-300 p-2 font-semibold bg-[#1e3a8a] text-white">
                    Currency
                  </th>
                  <th className="border border-gray-300 p-2 font-semibold bg-[#1e3a8a] text-white">
                    Amount
                  </th>
                  <th className="border border-gray-300 p-2 font-semibold bg-[#1e3a8a] text-white">
                    WBS
                  </th>
                  <th className="border border-gray-300 p-2 font-semibold bg-[#1e3a8a] text-white">
                    WBS Name
                  </th>
                </tr>
              </thead>
              <tbody>
                {purchaseRequest?.details?.map((item, i) => {
                  return (
                    <tr>
                      <td className={` border-gray-400 border  p-1 rounded`}>
                        {item?.quantity}
                      </td>
                      <td className={` border-gray-400 border  p-1 rounded`}>
                        {item?.description}
                      </td>
                      <td
                        className={` text-right border-gray-400 border  p-1 rounded`}
                      >
                        {item?.unitprice}
                      </td>
                      <td
                        className={` text-right border-gray-400 border  p-1 rounded`}
                      >
                        {item?.currency}
                      </td>
                      <td
                        className={` text-right  border-gray-400 border p-1 rounded`}
                      >
                        {item?.amount}
                      </td>
                      <td className={`  border-gray-400 border  p-1 rounded`}>
                        {item?.wbs}
                      </td>
                      <td className={`   border-gray-400 border  p-1 rounded`}>
                        {item?.wbsname}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td className="   p-2"></td>
                  <td className="  p-2"></td>
                  <td className=" p-2"></td>
                  <td className="  p-2 text-right font-medium">Total</td>
                  <td className=" p-2 w-28 text-right font-medium">
                    {totalAmount}
                  </td>
                  <td className="  p-2"></td>
                  <td className=" w-36  p-2"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-span-12">
            <p className="font-bold text-[#1e3a8a] text-xl mt-5 mb-5">
              Comments
            </p>
            <p>{purchaseRequest?.additionalcomment}</p>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ViewPr;
