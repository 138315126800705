import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import {
  fetchApprovers,
  fetchMaterials,
  fetchWbs,
} from "../../../../redux/action";
import Select from "react-select";

const initialDetail = {
  quantity: "",
  description: "",
  unitprice: "",
  currency: "AUD",
  amount: "0",
  wbs: [],
  wbsname: "",
};

const DetailsFormTable = ({
  formData,
  setFormData,
  details,
  handleInputChange,
  defaultData,
  disable,
  errors,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchApprovers());
    dispatch(fetchMaterials());
    dispatch(fetchWbs());
  }, []);

  const [initializedDetails, setInitializedDetails] = useState(
    formData.details || []
  );

  const handleDetailChange = (index, fieldName, value) => {
    const updatedDetails = [...formData.details];
    updatedDetails[index] = {
      ...updatedDetails[index],
      [fieldName]: value,
    };

    // If fieldName is "quantity" or "unitprice", calculate the amount
    if (fieldName === "quantity" || fieldName === "unitprice") {
      const quantity = parseFloat(updatedDetails[index].quantity);
      const unitPrice = parseFloat(updatedDetails[index].unitprice);

      if (!isNaN(quantity) && !isNaN(unitPrice)) {
        updatedDetails[index].amount = (quantity * unitPrice).toFixed(2);
      } else {
        updatedDetails[index].amount = "";
      }
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      details: updatedDetails,
    }));
  };

  const handleAddRow = () => {
    const newDetail = { ...initialDetail, wbs: "", wbsname: "" };

    setFormData((prevFormData) => ({
      ...prevFormData,
      details: [...prevFormData.details, newDetail],
    }));

    setInitializedDetails((prevInitializedDetails) => [
      ...prevInitializedDetails,
      newDetail,
    ]);
  };

  const handleRemoveRow = (index) => {
    setFormData((prevFormData) => {
      const updatedDetails = [...prevFormData.details];
      updatedDetails.splice(index, 1);
      return {
        ...prevFormData,
        details: updatedDetails,
      };
    });
  };

  const calculateTotalAmount = () => {
    let totalAmount = 0;

    formData.details.forEach((detail) => {
      if (detail && detail.amount) {
        const amount = parseFloat(detail.amount);
        if (!isNaN(amount)) {
          totalAmount += amount;
        }
      }
    });

    return totalAmount.toFixed(2);
  };

  const wbs = useSelector((state) => state.wbs.wbs);
  const [selectedWBSList, setSelectedWBSList] = useState(
    Array.from({ length: formData?.details?.length }, () => ({
      value: null,
      Description: "",
    }))
  );

  useEffect(() => {
    if (defaultData?.length > 0) {
      for (let i = 0; i < defaultData.length; i++) {
        const defaultValue = {
          value: defaultData[i].wbs,
          label: defaultData[i].wbs,
          Description: defaultData[i].wbsname,
        };

        handleWBSChange(i, defaultValue);
      }
    }
  }, [defaultData]);

  // const [wbsDescription, setWBSDescription] = useState("");
  const wbsOptions = wbs.map((item) => ({
    value: item.WBSelement,
    label: item.WBSelement,
    Description: item.Description,
  }));

  const sortedWBSOptions = wbsOptions.sort((a, b) => {
    const aParts = a.value.split('.');
    const bParts = b.value.split('.');
    for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
      const comparison = aParts[i].localeCompare(bParts[i]);
      if (comparison !== 0) {
        return comparison; 
      }
    }
    return a.value.localeCompare(b.value);
  });
  

  

  const handleWBSChange = (index, selectedOption) => {
    setSelectedWBSList((prevSelectedWBSList) => {
      const updatedSelectedWBSList = [...prevSelectedWBSList];
      updatedSelectedWBSList[index] = selectedOption || {
        value: null,
        Description: "",
      };
      return updatedSelectedWBSList;
    });

    if (selectedOption) {
      const selectedWBSObject = wbs.find(
        (item) => item.WBSelement === selectedOption.value
      );
      if (selectedWBSObject) {
        // Update the description directly in the selected detail row
        const updatedDetails = formData.details.map((detail, idx) => {
          if (idx === index) {
            return {
              ...detail,
              wbs: selectedOption.value,
              wbsname: selectedWBSObject.Description, // Set the wbsname property
            };
          }
          return detail;
        });

        setFormData((prevFormData) => ({
          ...prevFormData,
          details: updatedDetails,
        }));
      }
    } else {
      // If no WBS is selected, clear the description in the selected detail row
      const updatedDetails = formData.details.map((detail, idx) => {
        if (idx === index) {
          return {
            ...detail,
            wbs: "",
            wbsname: "", // Clear the wbsname property
          };
        }
        return detail;
      });

      setFormData((prevFormData) => ({
        ...prevFormData,
        details: updatedDetails,
      }));
    }
  };

  if (!formData.details) {
    return null;
  }

  return (
    <div>
      <table className="w-full border-collapse border border-gray-300 shadow-md text-sm">
        <thead>
          <tr>
            <th className="border border-gray-300 p-2 font-semibold">
              Quantity
            </th>
            <th className="border border-gray-300 p-2 font-semibold">
              Description
            </th>
            <th className="border border-gray-300 p-2 font-semibold">
              Unit Price
            </th>
            <th className="border border-gray-300 p-2 font-semibold">
              Currency
            </th>
            <th className="border border-gray-300 p-2 font-semibold">Amount</th>
            <th className="border border-gray-300 p-2 font-semibold">WBS</th>
            <th className="border border-gray-300 p-2 font-semibold">
              WBS Name
            </th>
            <th className="border border-gray-300 p-2 font-semibold"></th>
          </tr>
        </thead>
        <tbody>
          {formData?.details?.map((detail, index) => (
            <SingleRow
              disable={disable}
              error={errors?.length > 0 ? errors[index] : null}
              key={index}
              detail={detail}
              details={formData?.details}
              handleDetailChange={handleDetailChange}
              handleRemoveRow={handleRemoveRow}
              index={index}
              handleAddRow={handleAddRow}
              wbsOptions={sortedWBSOptions}
              selectedWBSList={selectedWBSList}
              handleWBSChange={handleWBSChange}
            />
          ))}

          <tr>
            <td className="   p-2"></td>
            <td className="  p-2"></td>
            <td className=" p-2"></td>
            <td className="  p-2 text-right font-medium">Total</td>
            <td className=" p-2 w-28 text-right font-medium">
              {calculateTotalAmount()}
            </td>
            <td className="  p-2"></td>
            <td className=" w-36  p-2"></td>
          </tr>
        </tbody>
      </table>

      <div className="flex w-full mt-10 items-start">
        <div className="w-full">
          <label
            className="block text-sm font-medium text-gray-900"
            htmlFor="additionalComments"
          >
            ADDITIONAL COMMENTS
          </label>
          <textarea
            disabled={disable}
            value={formData?.additionalcomment}
            onChange={(e) => handleInputChange(e)}
            name="additionalcomment"
            rows={2}
            className="bg-gray-200 border-gray-400 border  mt-1  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>
      </div>
    </div>
  );
};

function SingleRow({
  details,
  detail,
  handleDetailChange,
  handleRemoveRow,
  index,
  handleWBSChange,
  selectedWBSList,
  wbsOptions,
  handleAddRow,
  error,
  disable,
}) {
  const unitPrice = parseFloat(detail.unitprice) || 0; // Ensure a default value
  const amount = parseFloat(detail.amount) || 0; // Ensure a default value

  return (
    <tr>
      <td className="border border-gray-300 p-2">
        <input
          type="text"
          name="quantity"
          disabled={disable}
          value={detail.quantity || ""}
          onChange={(e) =>
            handleDetailChange(index, "quantity", e.target.value)
          }
          className={`w-full ${
            error?.quantity && "border-red-400 border-2"
          } border-gray-400 border bg-gray-200 px-1 py-2 rounded`}
        />
      </td>
      <td className="border border-gray-300 p-2">
        <input
          type="text"
          name="description"
          disabled={disable}
          value={detail.description || ""}
          onChange={(e) =>
            handleDetailChange(index, "description", e.target.value)
          }
          className={`w-36 ${
            error?.description && "border-red-400 border-2"
          } border-gray-400 border bg-gray-200 px-1 py-2 rounded`}
        />
      </td>
      <td className="border border-gray-300 p-2">
        <input
          type="number"
          disabled={disable}
          placeholder="0.00"
          step="0.01" // Adjust the step value if needed
          name="unitprice"
          value={unitPrice} // Use the parsed value directly
          onChange={(e) =>
            handleDetailChange(index, "unitprice", e.target.value)
          }
          className={`w-20 ${
            error?.unitprice && "border-red-600"
          } border-gray-400 border bg-gray-200 px-1 py-2 rounded`}
        />
      </td>
      <td className="border border-gray-300 p-2">
        <select
          name="currency"
          disabled={disable}
          value={detail.currency || ""} // Use the 'currency' property from 'detail'
          onChange={(e) =>
            handleDetailChange(index, "currency", e.target.value)
          }
          className={`bg-gray-200 rounded-md border-gray-400 border px-1 py-2 w-[100px]`}
        >
          <option value="AUD">AUD</option>
          <option value="NZD">NZD</option>
          <option value="USD">USD</option>
          <option value="EUR">EUR</option>
          <option value="GBP">GBP</option>
          <option value="CNY">CNY</option>
          <option value="IDR">IDR</option>
          <option value="VND">VND</option>
          <option value="MYR">MYR</option>
        </select>
      </td>
      <td className="border text-right border-gray-300 p-2 w-28">
        {isNaN(amount) ? "0.00" : amount.toFixed(2)}
      </td>
      <td className="border border-gray-300 p-2">
        <Select
          isDisabled={disable}
          value={selectedWBSList[index] || ""}
          onChange={(selectedOption) => handleWBSChange(index, selectedOption)}
          isClearable
          isSearchable
          isMulti={false}
          options={wbsOptions}
          placeholder="Select WBS..."
          menuPortalTarget={document.body} 
          className={`w-40 ${
            error?.wbs && "border-red-600"
          } text-[14px] border-gray-400 border rounded-md`}
          styles={{
            control: (base) => ({
              ...base,
              backgroundColor: "#E5E7EB",
            }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            menu: (base) => ({ ...base, width: "250px" }),
          }}
        />
      </td>
      <td className="border w-36 border-gray-300 p-2">
        {selectedWBSList[index]?.value
          ? selectedWBSList[index].Description
          : ""}
      </td>
      {!disable && (
        <td className="border border-gray-300 p-2">
          {index === details.length - 1 ? (
            <button
              type="button"
              onClick={handleAddRow}
              className="text-white text-sm bg-blue-900 hover:bg-blue-950 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded px-3 py-1"
            >
              +
            </button>
          ) : (
            <button
              type="button"
              onClick={() => handleRemoveRow(index)}
              className="text-white text-sm bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded px-3 py-1"
            >
              -
            </button>
          )}
        </td>
      )}
    </tr>
  );
}

export default DetailsFormTable;
