import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export const exportToExcel = async (jsonData, fileName) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet1");
  worksheet.spliceRows(1, worksheet.rowCount);

  const headers = Object.keys(jsonData[0]);
  const headerRow = worksheet.addRow(headers);

  headerRow.eachCell((cell) => {
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF1E3A8A" },
    };
    cell.font = {
      color: { argb: "FFFFFFFF" },
      bold: true,
    };
  });
  jsonData.forEach((row) => {
    const rowValues = Object.values(row).map((value) => (value ? value : " "));
    worksheet.addRow(rowValues);
  });

  worksheet.columns.forEach((column) => {
    let maxLength = 0;
    column.eachCell((cell) => {
      const columnLength = cell.value ? cell.value.toString().length : 10;
      if (columnLength > maxLength) {
        maxLength = columnLength;
      }
    });
    column.width = maxLength + 2;
  });

  const buffer = await workbook.xlsx.writeBuffer();

  const blob = new Blob([buffer], { type: "application/octet-stream" });
  saveAs(blob, `${fileName}.xlsx`);
};
