import React from "react";

const AppEmailInput = ({
  onChange,
  name,
  label,
  value,
  disable,
  isError,
  width,
}) => {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-900" htmlFor={name}>
        {label}
      </label>
      <input
        disabled={disable}
        value={value}
        className={`bg-gray-200 ${
          isError && "border-red-400 border-2"
        } border mt-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block ${
          width ? width : "w-full"
        }  p-2.5`}
        type="email"
        name={name}
        onChange={onChange}
      />
    </div>
  );
};

export default AppEmailInput;
