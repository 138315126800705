import { createSlice } from "@reduxjs/toolkit";
import{  fetchPendingAppRequest} from '../action'
// Create the slice
const initialState = {
    pendingRequests: [],
    error: null,
  };
const pendingRequestsSlice = createSlice({
    name: "approverPendingRequest",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchPendingAppRequest.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchPendingAppRequest.fulfilled, (state, action) => {
          state.loading = false;
          state.pendingRequests = action.payload;
          state.error = null;
        })
        .addCase(fetchPendingAppRequest.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        });
    },
  });
  

  
  // Export the reducer
  export default pendingRequestsSlice.reducer;