import React from "react";
import { IoCloseSharp } from "react-icons/io5";

const Modal = ({ isOpen, onClose, children, isScroll }) => {
  if (!isOpen) return;

  return (
    <div className="fixed py-10 inset-0 z-50 flex flex-col items-center justify-center w-screen h-screen bg-opacity-50 bg-gray-900">
      <div
        className={`relative ${
          isScroll && "overflow-y-scroll "
        } z-50 p-8 bg-white rounded-md shadow-lg xl:w-[60rem]`}
      >
        {children}
        <button
          className="absolute top-0 right-0 mt-6 mr-6 text-xl "
          onClick={onClose}
        >
          <IoCloseSharp />
        </button>
      </div>
    </div>
  );
};

export default Modal;
