// siteInstruction.js

import { createSlice } from "@reduxjs/toolkit";

const siteInstructionSlice = createSlice({
  name: "siteInstruction",
  initialState: {
    loading: false,
    error: null,
    siteInstruction: null,
  },
  reducers: {
    siteInstructionStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    siteInstructionSuccess: (state, action) => {
      state.loading = false;
      state.siteInstruction = action.payload;
    },
    siteInstructionFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  siteInstructionStart,
  siteInstructionSuccess,
  siteInstructionFailure,
} = siteInstructionSlice.actions;

export default siteInstructionSlice.reducer;
