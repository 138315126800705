import { createSlice } from "@reduxjs/toolkit";
import { fetchApprovers } from "../action";

const initialState = {
  loading: false,
  error: null,
  approvers: [],
};

const approversSlice = createSlice({
  name: "approver",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchApprovers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchApprovers.fulfilled, (state, action) => {
        state.loading = false;
        state.approvers= action.payload;
      })
      .addCase(fetchApprovers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default approversSlice.reducer;
