import React from "react";

const AppDateInput = ({ onChange, label, name, value, disable, isError }) => {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-900" htmlFor={name}>
        {label}
      </label>
      <input
        disabled={disable}
        value={value}
        className={`bg-gray-200 ${
          isError && "border-red-600"
        } border-2 mt-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
        type={"date"}
        name={name}
        onChange={onChange}
      />
    </div>
  );
};

export default AppDateInput;
