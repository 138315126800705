import React, { useState } from "react";
import Sidebar from "./Sidebar";
import ManageUsers from "./ManageUsers";
import EmailConfiguration from "./EmailConfiguration";

const AdminBeta = () => {
  const [activeComponent, setActiveComponent] = useState("ManageUsers");

  const renderComponent = () => {
    switch (activeComponent) {
      case "ManageUsers":
        return <ManageUsers />;
      case "EmailConfiguration":
        return <EmailConfiguration />;
      default:
        return <ManageUsers />;
    }
  };

  return (
    <div className="flex h-screen ">
      <div className="w-1/5 rounded-md overflow-hidden">
        <Sidebar
          activeComponent={activeComponent}
          setActiveComponent={setActiveComponent}
        />
      </div>
      <div className="w-4/5 bg-white rounded-md min-h-fit  transition-all">
        {renderComponent()}
      </div>
    </div>
  );
};

export default AdminBeta;
