import React, { useState, useEffect } from "react";
import AppTextInput from "../../../UI/Input/AppTextInput";
import AppDateInput from "../../../UI/Input/AppDateInput";
import DetailsFormTable from "./DetailsFormTable";
import { useSelector, useDispatch } from "react-redux";
import {
  performPurchaseRequest,
  generatePrNumber,
  performPurchaseRequestWithAppr,
  uploadFiles,
} from "../../../../redux/action";
import { toast } from "react-hot-toast";
import MaterialSelector from "./MaterialSelector";
import FormDynamicValues from "./FormDynamicValues";
import ApproverSelector from "./ApproverSelector";
import ManagerOnlyInput from "./ManagerOnlyInput";
import { Link, useNavigate } from "react-router-dom";
import { BsCheckCircle } from "react-icons/bs";
import Modal from "../../../UI/Modal";
import DropZone from "../../../UI/DropZone";
import Loader from "../../../UI/Loader";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FaInfoCircle } from "react-icons/fa";
import ExternalContractor from "./ExternalContractor";
const initialDetail = {
  quantity: "",
  description: "",
  unitprice: "",
  currency: "AUD",
  amount: "",
  wbs: "",
  wbsname: "",
};
const initialContractorState = {
  externalContractor: false,
  ContractorsName: "",
  ContractorsEmail: "",
  SubContractorsEmail: "",
};
const PurhcaseRequestForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const prnumber = useSelector((state) => state.prnumber.prnumber);
  const submittedby = useSelector((state) => state.auth.username);
  const [selectedApprovers, setSelectedApprovers] = useState([]);
  const [openSuccessModal, setOpenSucessModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const [externalContractor, setExternalContractor] = useState(
    initialContractorState
  );
  const [isSupplierSelected, setIsSupplierSelected] = useState("no");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const initialDetail2 = {
    quantity: false,
    description: false,
    unitprice: false,
    currency: false,
    amount: false,
    wbs: false,
    wbsname: false,
  };

  const validationErrors = {
    details: [initialDetail2],
  };

  const [errors, setErrors] = useState(validationErrors);
  useEffect(() => {
    const fetchPRNumber = () => {
      dispatch(generatePrNumber());
    };
    fetchPRNumber();
    const intervalId = setInterval(fetchPRNumber, 1000);
    return () => clearInterval(intervalId);
  }, [dispatch]);

  const [formData, setFormData] = useState({
    prnumber,
    prdate: new Date().toISOString(),
    submittedby,
    prstatus: "Draft",
    projectnumber: "",
    inScope: "",
    prtitle: "",
    vendor: "",
    materialgrp: "",
    quoteno: "",
    leadtime: "",
    requestedby: "",
    paymentterms: "",
    duedate: "",
    details: [initialDetail],
    additionalcomment: "",
    externalContractor: externalContractor,
  });

  const handleApproverSelection = (approversDetails) => {
    setSelectedApprovers(approversDetails);
  };
  const [details, setDetails] = useState([initialDetail]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "prdate") {
      const d = new Date(value).toISOString().split("T")[0];
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: d,
      }));
      return;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      externalContractor,
    }));
  }, [externalContractor]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await dispatch(generatePrNumber());
      if (isSupplierSelected === "yes") {
        if (
          !externalContractor.ContractorsName ||
          !externalContractor.ContractorsEmail
        ) {
          toast.error("Please fill in contractor's details");
          setLoading(false);
          return;
        }
        if (
          externalContractor.ContractorsEmail &&
          !emailRegex.test(externalContractor.ContractorsEmail)
        ) {
          toast.error("Please enter a valid Contractor's Email");
          setLoading(false);
          return;
        }
        if (
          externalContractor.SubContractorsEmail &&
          !emailRegex.test(externalContractor.SubContractorsEmail)
        ) {
          toast.error("Please enter a valid Sub-Contractor's Email");
          setLoading(false);
          return;
        }
      }

      if (files.length > 0) {
        const res = await dispatch(uploadFiles(prnumber, files));

        if (!res.success) {
          setLoading(false);
          toast.error(res.error.message);
          return;
        }
      }
      const updatedFormData = {
        ...formData,
        prstatus: "Draft",
        prnumber: prnumber,
        externalContractor,
      };
      await dispatch(performPurchaseRequest(updatedFormData));
      setOpenSucessModal(true);
      toast.success("Purchase Request created successfully");
      navigate("/dashboard/pr-dashboard");
    } catch (error) {
      toast.error(error.message || "An error occurred");
      console.error("Error in form submission:", error);
    }
    setLoading(false);
  };

  const handleSubmitForApproval = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setLoading(true);
    await dispatch(generatePrNumber());
    if (validateForm()) {
      setLoading(false);
      return;
    }
    if (isSupplierSelected === "yes") {
      if (
        !externalContractor.ContractorsName ||
        !externalContractor.ContractorsEmail
      ) {
        toast.error("Please fill in Contractor's Details");
        setLoading(false);
        return;
      }
      if (
        externalContractor.ContractorsEmail &&
        !emailRegex.test(externalContractor.ContractorsEmail)
      ) {
        toast.error("Please enter a valid Contractor's Email");
        setLoading(false);
        return;
      }
      if (
        externalContractor.SubContractorsEmail &&
        !emailRegex.test(externalContractor.SubContractorsEmail)
      ) {
        toast.error("Please enter a valid Sub-Contractor's Email");
        setLoading(false);
        return;
      }
    }
    if (files.length > 0) {
      const uploadSuccess = await dispatch(uploadFiles(prnumber, files));
      if (!uploadSuccess) {
        setLoading(false);
        toast.error("File upload failed");
        return;
      }
    }
    try {
      const updatedFormData = {
        ...formData,
        prnumber: prnumber,
        prstatus: "Pending",
        approvers: selectedApprovers,
        externalContractor,
      };
      await dispatch(performPurchaseRequestWithAppr(updatedFormData));
      setOpenSucessModal(true);
      toast.success("Purchase Request Submitted for approval");
      setFormData([]);
      navigate("/dashboard/pr-dashboard");
    } catch (error) {
      toast.error(error);
      console.error("Error submitting form:", error);
    }
    setLoading(false);
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  useEffect(() => {
    if (isSubmitted) {
      const updatedErrors = { ...errors };
      const updatedErrorMessages = {
        ...errorMessages,
        details: errorMessages.details
          ? [...errorMessages.details]
          : formData.details.map(() => ({})),
      };

      // Helper function to update error state
      const updateErrorState = (field, value, errorMessage) => {
        if (value !== undefined && value !== null && value !== "") {
          updatedErrors[field] = false;
          updatedErrorMessages[field] = "";
        } else if (formData[field] !== undefined) {
          updatedErrors[field] = true;
          updatedErrorMessages[field] = errorMessage;
        }
      };

      updateErrorState("prtitle", formData.prtitle, "PR Title is required.");
      updateErrorState(
        "projectnumber",
        formData.projectnumber,
        "Project Number is required."
      );
      updateErrorState("vendor", formData.vendor, "Vendor is required.");
      updateErrorState(
        "inScope",
        formData.inScope,
        "Within scope is required."
      );
      updateErrorState(
        "materialgrp",
        formData.materialgrp,
        "Material Group is required."
      );
      updateErrorState(
        "quoteno",
        formData.quoteno,
        "Quote Number is required."
      );
      updateErrorState("leadtime", formData.leadtime, "Lead Time is required.");
      updateErrorState(
        "requestedby",
        formData.requestedby,
        "Requested By is required."
      );
      updateErrorState(
        "paymentterms",
        formData.paymentterms,
        "Payment Terms are required."
      );
      updateErrorState("duedate", formData.duedate, "Due Date is required.");

      if (selectedApprovers !== undefined) {
        updateErrorState(
          "approver",
          selectedApprovers?.length > 0,
          "At least one approver must be selected."
        );
      }

      formData?.details?.forEach((item, index) => {
        if (!updatedErrorMessages?.details[index]) {
          updatedErrors.details[index] = {};
          updatedErrorMessages.details[index] = {};
        }

        const updateDetailErrorState = (field, value, errorMessage) => {
          if (value !== undefined && value !== null && value !== "") {
            if (field === "quantity" && parseFloat(value) <= 0) {
              updatedErrors.details[index][field] = true;
              updatedErrorMessages.details[index][field] =
                "Quantity must be greater than 0.";
            } else {
              updatedErrors.details[index][field] = false;
              updatedErrorMessages.details[index][field] = "";
            }
          } else if (item[field] !== undefined) {
            updatedErrors.details[index][field] = true;
            updatedErrorMessages.details[index][field] = errorMessage;
          }
        };

        updateDetailErrorState("amount", item.amount, "Amount is required.");
        updateDetailErrorState(
          "description",
          item.description,
          "Description is required."
        );
        updateDetailErrorState(
          "quantity",
          item.quantity,
          "Quantity is required."
        );
        updateDetailErrorState(
          "unitprice",
          item.unitprice,
          "Unit Price is required."
        );
        updateDetailErrorState(
          "currency",
          item.currency,
          "Currency is required."
        );
        updateDetailErrorState("wbs", item.wbs, "WBS is required.");
        updateDetailErrorState(
          "wbsname",
          item.wbsname,
          "WBS Name is required."
        );
      });

      if (externalContractor.externalContractor) {
        updateErrorState(
          "ContractorsName",
          externalContractor.ContractorsName,
          "Contractor's Name is required."
        );
        if (externalContractor.ContractorsEmail !== undefined) {
          updateErrorState(
            "ContractorsEmail",
            emailRegex.test(externalContractor.ContractorsEmail),
            "Valid Contractor's Email is required."
          );
        }
        if (
          externalContractor.SubContractorsEmail !== undefined &&
          externalContractor.SubContractorsEmail !== ""
        ) {
          updateErrorState(
            "SubContractorsEmail",
            emailRegex.test(externalContractor.SubContractorsEmail),
            "Valid Subcontractor's Email is required."
          );
        } else {
          updatedErrors.SubContractorsEmail = false;
          updatedErrorMessages.SubContractorsEmail = "";
        }
      }

      setErrors(updatedErrors);
      setErrorMessages(updatedErrorMessages);
    }
  }, [formData, externalContractor, selectedApprovers]);
  useEffect(() => {
    if (isSubmitted) {
      validateForm();
    }
  }, [isSubmitted]);
  const validateForm = () => {
    const initialDetail = {
      quantity: false,
      description: false,
      unitprice: false,
      currency: false,
      amount: false,
      wbs: false,
      wbsname: false,
    };
    const initialDetailMessages = {
      quantity: "",
      description: "",
      unitprice: "",
      currency: "",
      amount: "",
      wbs: "",
      wbsname: "",
    };

    const validationErrors = {
      details: formData.details.map(() => ({ ...initialDetail })),
    };

    const validationMessages = {
      details: formData.details.map(() => ({ ...initialDetailMessages })),
    };
    if (!formData.prtitle) {
      validationErrors.prtitle = true;
      validationMessages.prtitle = "PR Title is required.";
    }

    if (!formData.projectnumber) {
      validationErrors.projectnumber = true;
      validationMessages.projectnumber = "Project Number is required.";
    }

    if (!formData.vendor) {
      validationErrors.vendor = true;
      validationMessages.vendor = "Vendor is required.";
    }

    if (!formData.inScope) {
      validationErrors.inScope = true;
      validationMessages.inScope = "Within Scope is required.";
    }

    if (!formData.materialgrp) {
      validationErrors.materialgrp = true;
      validationMessages.materialgrp = "Material Group is required.";
    }

    if (!formData.quoteno) {
      validationErrors.quoteno = true;
      validationMessages.quoteno = "Quote Number is required.";
    }

    if (!formData.leadtime) {
      validationErrors.leadtime = true;
      validationMessages.leadtime = "Lead Time is required.";
    }

    if (!formData.requestedby) {
      validationErrors.requestedby = true;
      validationMessages.requestedby = "Requested By is required.";
    }

    if (!formData.paymentterms) {
      validationErrors.paymentterms = true;
      validationMessages.paymentterms = "Payment Terms are required.";
    }

    if (!formData.duedate) {
      validationErrors.duedate = true;
      validationMessages.duedate = "Due Date is required.";
    }

    if (selectedApprovers?.length === 0) {
      validationErrors.approver = true;
      validationMessages.approver = "At least one approver must be selected.";
    }

    validationErrors.details = formData.details.map((item, index) => {
      const detailErrors = {};
      const detailMessages = {};

      if (!item.amount) {
        detailErrors.amount = true;
        detailMessages.amount = "Amount is required.";
      }
      if (!item.description) {
        detailErrors.description = true;
        detailMessages.description = "Description is required.";
      }
      if (!item.quantity || parseFloat(item.quantity) <= 0) {
        detailErrors.quantity = true;
        detailMessages.quantity = "Quantity must be greater than 0.";
      }
      if (!item.unitprice) {
        detailErrors.unitprice = true;
        detailMessages.unitprice = "Unit Price is required.";
      }

      if (!item.currency) {
        detailErrors.currency = true;
        detailMessages.currency = "Currency is required.";
      }
      if (!item.wbs) {
        detailErrors.wbs = true;
        detailMessages.wbs = "WBS is required.";
      }

      validationMessages.details[index] = detailMessages;
      return detailErrors;
    });

    if (externalContractor.externalContractor) {
      if (!externalContractor.ContractorsName) {
        validationErrors.ContractorsName = true;
        validationMessages.ContractorsName = "Contractor's Name is required.";
      } else {
        validationErrors.ContractorsName = false;
      }

      if (
        !externalContractor.ContractorsEmail ||
        !emailRegex.test(externalContractor.ContractorsEmail)
      ) {
        validationErrors.ContractorsEmail = true;
        validationMessages.ContractorsEmail =
          "Valid Contractor's Email is required.";
      } else {
        validationErrors.ContractorsEmail = false;
      }

      if (
        externalContractor.SubContractorsEmail &&
        !emailRegex.test(externalContractor.SubContractorsEmail)
      ) {
        validationErrors.SubContractorsEmail = true;
        validationMessages.SubContractorsEmail =
          "Valid Subcontractor's Email is required.";
      } else {
        validationErrors.SubContractorsEmail = false;
      }
    }

    if (isSubmitted) {
      setErrors(validationErrors);
      setErrorMessages(validationMessages);
    }

    const hasErrors = Object.values(validationErrors).some((field) => {
      if (Array.isArray(field)) {
        return field.some((detailItem) =>
          Object.values(detailItem).some((error) => error === true)
        );
      }
      return field === true;
    });

    if (hasErrors && isSubmitted) {
      toast.error("Please fill all the required fields");
    }

    return hasErrors;
  };
  const hasErrorMessages = (messages) => {
    if (!messages) return false;

    if (typeof messages === "object") {
      return Object.values(messages).some((value) =>
        typeof value === "string"
          ? value.trim() !== ""
          : hasErrorMessages(value)
      );
    }

    return false;
  };

  return (
    <section className=" shadow-lg p-5  bg-white">
      {loading && (
        <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 backdrop-blur-md">
          <Loader />
        </div>
      )}
      <h1 className="text-2xl font-bold leading-6 text-slate-700 mb-8 text-center">
        Visy Glass Purchase Requisition Request Form
      </h1>

      <div className=" flex gap-x-5 justify-between items-end">
        <FormDynamicValues
          prnumber={prnumber}
          handleInputChange={handleInputChange}
          prdate={formData.prdate}
          submittedby={formData.submittedby}
          status={formData.prstatus}
        />

        <Link to={`/dashboard/initiate-site-instruction?prnumber=${prnumber}`}>
          <button className="bg-[#3bb7b6] rounded-md text-white px-5 py-2">
            Add Site Instruction
          </button>
        </Link>
      </div>

      <form
        onSubmit={handleFormSubmit}
        className="grid mt-5 grid-cols-12 gap-x-5"
      >
        <div className="col-span-3   flex flex-col gap-y-3">
          <AppTextInput
            onChange={handleInputChange}
            name={"prtitle"}
            label={"PR TITLE"}
            isError={errors.prtitle}
          />
          <AppTextInput
            onChange={handleInputChange}
            name={"projectnumber"}
            label={"PROJECT NO."}
            isError={errors.projectnumber}
          />
          <AppTextInput
            onChange={handleInputChange}
            name={"vendor"}
            label={"VENDOR"}
            isError={errors.vendor}
          />
          <div>
            <label
              className="block text-sm font-medium text-gray-900"
              htmlFor={"Scope"}
            >
              WITHIN SCOPE?
            </label>
            <select
              name="inScope"
              onChange={handleInputChange}
              className={`mt-1 ${
                errors.inScope && "border-red-600"
              } bg-gray-200 text-[14px]  rounded-md border-gray-400 border-2 px-2 py-2  w-full`}
            >
              <option value="">Select</option>
              <option value="In Scope">In Scope</option>
              <option value="Out of Scope">Out of Scope</option>
            </select>
          </div>
          <MaterialSelector
            error={errors?.materialgrp}
            setFormData={setFormData}
          />
          <AppTextInput
            isError={errors.quoteno}
            onChange={handleInputChange}
            name={"quoteno"}
            label={"QUOTE NO"}
          />
          <AppTextInput
            isError={errors.leadtime}
            onChange={handleInputChange}
            name={"leadtime"}
            label={"LEAD TIME"}
          />
          <AppTextInput
            isError={errors.requestedby}
            onChange={handleInputChange}
            name={"requestedby"}
            label={"REQUESTED BY"}
          />
          <AppTextInput
            isError={errors.paymentterms}
            onChange={handleInputChange}
            name={"paymentterms"}
            label={"PAYMENT TERMS"}
          />
          <AppDateInput
            isError={errors.duedate}
            onChange={handleInputChange}
            name={"duedate"}
            label={"DUE DATE"}
          />
        </div>
        <div className="col-span-9 mt-5 ">
          <DetailsFormTable
            formData={formData}
            setDetails={setDetails}
            setFormData={setFormData}
            details={details}
            handleInputChange={handleInputChange}
            errors={errors.details}
            disable={false}
          />

          <div className="my-4">
            <label
              className="block text-sm font-medium text-gray-900 mb-2 "
              htmlFor="additionalComments"
            >
              ADD ATTACHMENTS
              <FaInfoCircle
                data-tooltip-id="my-tooltip-data-html"
                data-tooltip-html={`
            <div >
              <ul style="list-style-type: disc; padding-left: 20px; margin: 0; ">
                <li style="margin-bottom: 5px; color: #fff;">Up to 3 attachments</li>
                <li style="margin-bottom: 5px; color: #fff;">Each attachment up to 5 MB</li>
                <li style="margin-bottom: 5px; color: #fff;">Keep the file names unique</li>
              </ul>
            </div>
          `}
                className="text-red-500 ml-2 inline-block cursor-pointer text-xl "
              />
              <ReactTooltip
                id="my-tooltip-data-html"
                place="right-start"
                effect="solid"
                className="z-99"
              ></ReactTooltip>
            </label>
            <DropZone files={files} setFiles={setFiles} />
          </div>
          {/* external contractor fields here */}
          <ExternalContractor
            externalContractor={externalContractor}
            setExternalContractor={setExternalContractor}
            isSupplierSelected={isSupplierSelected}
            setIsSupplierSelected={setIsSupplierSelected}
            errors={errors}
          />
          {/* endm external contractor fields here */}

          <div className="flex ">
            <button
              className="bg-[#194099] shadow-md rounded-md text-white px-5 py-2 mt-3"
              type="submit"
            >
              {loading ? "Saving..." : "Save & Exit"}
            </button>
          </div>
          <ApproverSelector
            isError={errors?.approver}
            onApproverSelection={handleApproverSelection}
          />
          <button
            className="bg-[#3bb75a] shadow-md rounded-md text-white px-5 py-2 mt-4 hover:bg-[#2b8040]"
            type="button"
            onClick={handleSubmitForApproval}
          >
            Submit for Approval
          </button>

          <ManagerOnlyInput />
          {isSubmitted && errorMessages && hasErrorMessages(errorMessages) && (
            <div className="mt-4 p-4 border border-red-700 bg-red-100 text-red-700 rounded">
              <h3 className="text-md font-semibold text-red-700 mb-2">
                Please check the following :
              </h3>
              <ul className="list-disc list-inside text-sm">
                {Object.entries(errorMessages).map(([key, error]) =>
                  typeof error === "string" && error ? (
                    <li key={key}>{error}</li>
                  ) : null
                )}
                {errorMessages.details && (
                  <>
                    {[
                      ...new Set(
                        errorMessages.details.flatMap((detailError) =>
                          Object.values(detailError).filter(Boolean)
                        )
                      ),
                    ].map((message, index) => (
                      <li key={`detail-error-${index}`}>{message}</li>
                    ))}
                  </>
                )}
              </ul>
            </div>
          )}
        </div>
      </form>
      {openSuccessModal && (
        <Modal
          onClose={() => setOpenSucessModal(false)}
          isOpen={openSuccessModal}
        >
          <div className="flex justify-center mb-5 text-4xl">
            <BsCheckCircle className=" text-green-400" />
          </div>
          <p className="text-center font-bold">
            Purchase request created succesfully
          </p>
          <p className="text-center text-[15px]">{prnumber}</p>

          <div className="flex text-[14px] mb-5 justify-center mt-5 gap-x-5 items-center">
            <Link to={"/dashboard/pr-dashboard"}>
              <button className="bg-[#1e3a8a] text-white px-2 py-2 rounded-md">
                Go to Dashboard
              </button>
            </Link>
            <Link to={"/dashboard/new-pr-form"}>
              <button className="bg-[#1e3a8a] text-white px-2 py-2 rounded-md">
                Create New PR
              </button>
            </Link>
          </div>
        </Modal>
      )}
    </section>
  );
};

export default PurhcaseRequestForm;
