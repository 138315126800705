import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaRegFilePdf } from "react-icons/fa";
import MyDataGrid from "../../../UI/MyDataGrid";
import { useDispatch, useSelector } from "react-redux";
import { fetchPendingAppRequestSi } from "../../../../redux/action";
import Loader from "../../../UI/Loader";
import moment from "moment";
import { toast } from "react-hot-toast";

const SiTable = () => {
  const [loading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const pageSize = 10;
  const dispatch = useDispatch();
  const pendingRequests = useSelector(
    (state) => state.approverPendingRequestSi.pendingRequestsSi
  );
  // console.log(pendingRequests);

  const [siRequestsData, setSiRequestsData] = useState([]);

  useEffect(() => {
    if (pendingRequests?.length > 0) {
      const data = pendingRequests.map((row) => ({
        ...row,
        id: row.sino + "&" + row.sistatus,
      }));
      setSiRequestsData(data);
    }
  }, [pendingRequests]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchPendingAppRequestSi());
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  const initialSearch = {
    showRecords: "30 Days",
    status: "All",
    inScope: "All",
    search: "",
    from: "",
    to: "",
  };

  const columns = [
    {
      field: "sino",
      headerName: "SI No.",
      width: 140,
      renderCell: (params) => {
        const siNo = params.value;
        return <p className="  font-medium">{siNo}</p>;
      },
    },
    {
      field: "siDate",
      headerName: "SI Date",
      width: 100,
      renderCell: (params) => {
        const siDateStr = params.value;
        const formattedDate = moment(siDateStr).format("DD/MM/YYYY");
        return <p>{formattedDate}</p>;
      },
    },

    {
      field: "projectnumber",
      headerName: "Project No.",
      width: 100,
    },
    {
      field: "time",
      headerName: "Time Extension",
      width: 100,
      renderCell: (params) => {
        const timeExtension = params.row.timeExtension;
        const timeExtensionUnit = params.row.timeExtensionUnit;
        const amountText = `${timeExtension} ${timeExtensionUnit}`;
        return <p>{amountText}</p>;
      },
    },
    {
      field: "contractScope",
      headerName: "In-Scope?",
      width: 100,
      renderCell: (params) => {
        const status = params.value;
        return <p>{status}</p>;
      },
    },
    {
      field: "siDate",
      headerName: "SI Date",
      width: 100,
      renderCell: (params) => {
        const siDateStr = params.value;
        const formattedDate = moment(siDateStr).format("DD/MM/YYYY");
        return <p>{formattedDate}</p>;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 100,
      renderCell: (params) => {
        const totalValue = params.row.totalValue;
        const totalValueCurrency = params.row.totalValueCurrency;
        const amountText = `${totalValue} ${totalValueCurrency}`;
        return <p>{amountText}</p>;
      },
    },
    {
      field: "sistatus",
      headerName: "SI Status",
      width: 100,
      renderCell: (params) => {
        const status = params.value;

        let bgColor = "bg-green-500";
        if (status === "Draft") {
          bgColor = "bg-orange-300";
        }

        if (status === "Pending") {
          bgColor = "bg-red-500";
        }

        return (
          <p className={`${bgColor} px-2 text-[12px] py-1 rounded-md `}>
            {status}
          </p>
        );
      },
    },

    {
      field: "actions",
      headerName: "Actions Section:",
      width: 150,
      renderCell: (params) => {
        const prnumber = params.row.prnumber; // Update to the correct field
        const sistatus = params.row.sistatus; // Update to the correct field
        const bgColor =
          sistatus === "Draft"
            ? "bg-orange-300"
            : sistatus === "Pending"
            ? "bg-red-500"
            : "bg-green-500";

        return (
          <div className="flex gap-x-4">
            <Link to={`/dashboard/review-si?prnumber=${prnumber}`}>
              <button className="text-[14px] text-blue-600 underline">
                Review
              </button>
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full">
      <div className="min-h-screen h-100% bg-white py-3">
        <h1 className="font-bold text-center text-2xl">
          Pending Site Instruction Requests For Approval
        </h1>
        <main className="py-8 w-full px-4 sm:px-8">
          <div className="w-full">
            {loading ? (
              <Loader />
            ) : (
              <>
                <MyDataGrid
                  bgColor="#1e3a8a"
                  rows={siRequestsData}
                  columns={columns}
                  currentPage={pageNo}
                  pageSize={pageSize}
                  onPageChange={setPageNo}
                />
              </>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default SiTable;
