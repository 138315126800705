import React, { useEffect } from "react";
import Modal from "../../UI/Modal";
import { getSiById } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../UI/Loader";

const ViewSi = ({ isOpen, setOpen, prNumber }) => {
  const dispatch = useDispatch();

  const purchaseRequest = useSelector((state) => state.singleSi.siRequests);

  useEffect(() => {
    dispatch(getSiById(prNumber));
  }, [dispatch]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const val = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;

    return val;
  };

  if (!purchaseRequest) return;

  return (
    <Modal isScroll={true} isOpen={isOpen} onClose={() => setOpen(false)}>
      {!purchaseRequest ? (
        <Loader></Loader>
      ) : (
        <div className="grid grid-cols-12 gap-x-5 items-start">
          <div className="col-span-7">
            <div className="flex gap-x-2 items-center">
              <p className="font-bold text-[#1e3a8a] text-xl">
                Site Instruction Details
              </p>
            </div>

            <div className="mt-5 text-[14px] flex flex-col gap-y-2 text-gray-600">
              <p>Si No. : {purchaseRequest?.sino}</p>
              <p>Si Title : {purchaseRequest?.sititle}</p>
              <p>Si Date : {formatDate(purchaseRequest?.siDate)}</p>
              <p>Linked PR No. : {purchaseRequest?.prnumber}</p>
              <p>Project No. : {purchaseRequest?.projectnumber}</p>
              <p>SI Status : {purchaseRequest?.sistatus}</p>
              <p>Contract scope : {purchaseRequest?.contractScope}</p>
              <p>Description Of Work : {purchaseRequest?.descriptionOfWork}</p>
              <p>
                Reson For Instruction: {purchaseRequest?.resonForInstruction}
              </p>
              <p>
                Time Extension : {purchaseRequest?.timeExtension?.value}{" "}
                {purchaseRequest?.timeExtension?.time}
              </p>
              <p>
                {" "}
                Raised By : <b> Name</b> {purchaseRequest?.raisedBy?.name}{" "}
                <b> Designation</b> {purchaseRequest?.raisedBy?.designation}{" "}
                <b> Team</b> {purchaseRequest?.raisedBy?.team}
              </p>
              <p>
                {" "}
                Total Value :{
                  purchaseRequest?.totalValueSiteInstruction?.value
                }{" "}
                <b></b> {purchaseRequest?.totalValueSiteInstruction?.currency}
              </p>
            </div>
          </div>
          <div className="col-span-5">
            <div className="">
              <p className="font-bold text-[#1e3a8a] text-xl mb-5">Approvers</p>
              {purchaseRequest?.approvers?.map((item, i) => (
                <div className="mt-5" key={i}>
                  <div className="flex gap-x-3 items-center">
                    <p>Approver {i + 1}:</p>
                    <p
                      className={` ${
                        item?.status === "Approved"
                          ? "text-green-600"
                          : item?.status === "Pending"
                          ? "text-orange-700"
                          : item?.status === "Rejected" && "text-gray-800"
                      } `}
                    >
                      {item?.status}
                    </p>
                  </div>
                  <div className="mt-2 text-[14px] flex flex-col gap-y-2 text-gray-600">
                    <p>Name : {item.name}</p>
                    <p>Email: {item.email}</p>
                    <p>Comments : {item.comments}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* <div className="col-span-12">
            <p className="font-bold text-[#1e3a8a] text-xl mt-5 mb-5">
              Comments
            </p>
            <p>{purchaseRequest?.additionalcomment}</p>
          </div> */}
        </div>
      )}
    </Modal>
  );
};

export default ViewSi;
