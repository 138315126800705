import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaRegFilePdf } from "react-icons/fa6";
import MyDataGrid from "../../../UI/MyDataGrid";
import { useDispatch, useSelector } from "react-redux";
import { fetchPendingAppRequest } from "../../../../redux/action";
import Loader from "../../../UI/Loader";
import moment from "moment";
import { toast } from "react-hot-toast";

const Prtable = () => {
  const [loading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const pageSize = 10;
  const dispatch = useDispatch();
  const pendingRequests = useSelector(
    (state) => state.approverPendingRequest.pendingRequests
  );
  // console.log(pendingRequests);

  const [purchaseRequestsData, setPurchaseRequestsData] = useState([]);

  useEffect(() => {
    if (pendingRequests?.length > 0) {
      const data = pendingRequests.map((row) => ({
        ...row,
        id: row.prnumber + "&" + row.prstatus,
      }));
      setPurchaseRequestsData(data);
    }
  }, [pendingRequests]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchPendingAppRequest());
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  const initialSearch = {
    showRecords: "30 Days",
    status: "All",
    inScope: "All",
    search: "",
    from: "",
    to: "",
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   let filters = { ...filter };
  //   filters[name] = value;
  //   setFilter(filters);
  // };

  const columns = [
    {
      field: "prnumber",
      headerName: "PR No.",
      width: 140,
      renderCell: (params) => {
        const prNo = params.value;
        return <p className="  font-medium">{prNo}</p>;
      },
    },
    {
      field: "prdate",
      headerName: "PR Date",
      width: 100,
      renderCell: (params) => {
        const prDateStr = params.value;
        const formattedDate = moment(prDateStr).format("DD/MM/YYYY");
        return <p>{formattedDate}</p>;
      },
    },

    {
      field: "projectnumber",
      headerName: "Project No.",
      width: 100,
    },
    {
      field: "vendor",
      headerName: "Vendor Name",
      width: 150,
    },
    {
      field: "inScope",
      headerName: "In-Scope?",
      width: 100,
      renderCell: (params) => {
        const status = params.value;
        return <p>{status}</p>;
      },
    },
    {
      field: "duedate",
      headerName: "Due Date",
      width: 100,
      renderCell: (params) => {
        const dueDateStr = params.value;
        const formattedDate = moment(dueDateStr).format("DD/MM/YYYY");
        return <p>{formattedDate}</p>;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 100,
    },
    {
      field: "prstatus",
      headerName: "PR Status",
      width: 100,
      renderCell: (params) => {
        const status = params.value;

        let bgColor = "bg-green-500";
        if (status === "Draft") {
          bgColor = "bg-orange-300";
        }

        if (status === "Pending") {
          bgColor = "bg-red-500";
        }

        return (
          <p className={`${bgColor} px-2 text-[12px] py-1 rounded-md `}>
            {status}
          </p>
        );
      },
    },
    {
      field: "sinumber",
      headerName: "Linked SI No.",
      width: 120,
      renderCell: (params) => {
        const sinumber = params.value;
        if (sinumber) {
          // If sinumber is not empty, render as hyperlink
          return (
            <a
              className="underline text-blue-500 font-medium"
              href={`/path-to-your-si/${sinumber}`}
            >
              {sinumber}
            </a>
          );
        } else {
          return "Not Linked";
        }
      },
    },
    {
      field: "actions",
      headerName: "Actions Section:",
      width: 150,
      renderCell: (params) => {
        const prnumber = params.row.prnumber;
        const status = params.row.prstatus;
        const bgColor =
          status === "Draft"
            ? "bg-orange-300"
            : status === "Pending"
            ? "bg-red-500"
            : "bg-green-500";

        return (
          <div className="flex gap-x-4">
            <Link to={`/dashboard/review-pr?prnumber=${prnumber}`}>
              <button className="text-[14px] text-blue-600 underline">
                Review
              </button>
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full">
      <div className="min-h-screen h-100% bg-white py-3">
        <h1 className=" font-bold text-center text-2xl ">
          Pending Purchase Request For Approval
        </h1>
        <main className="py-8 w-full  px-4 sm:px-8">
          <div className="w-full">
            {/* <PRFilters handleInputChange={handleInputChange} filter={filter} /> */}
            {loading ? (
              <Loader />
            ) : (
              <>
                <MyDataGrid
                  bgColor="#1e3a8a"
                  rows={pendingRequests}
                  columns={columns}
                  currentPage={pageNo}
                  pageSize={pageSize}
                  onPageChange={setPageNo}
                />
              </>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Prtable;
