import {generaterSiNumber} from '../action'
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    sinumber: "",
    loading: false,
    error: null,
  };
  
  const sinumberSlice = createSlice({
    name: 'sinumber',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(generaterSiNumber.pending, (state) => {
          state.loading = true;
        })
        .addCase(generaterSiNumber.fulfilled, (state, action) => {
          state.loading = false;
          state.sinumber = action.payload;
          state.error = null;
        })
        .addCase(generaterSiNumber.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        });
    },
  });
  export default sinumberSlice.reducer;  