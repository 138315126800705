import { createSlice } from "@reduxjs/toolkit";
import{ fetchPurchaseRequests} from "../action.js"


const purchaseRequestSlice = createSlice({
  name: "purchaseRequests",
  initialState: {
    purchaseRequests: [],
    isLoading: false,
    isError: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPurchaseRequests.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchPurchaseRequests.fulfilled, (state, action) => {
        state.isLoading = false;
        state.purchaseRequests = action.payload;
      })
      .addCase(fetchPurchaseRequests.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload;
      });
  },
});

export default purchaseRequestSlice.reducer;
