import React from "react";
import Modal from "./Modal";
import { toast } from "react-hot-toast";

const CancelPrModal = ({ isOpen, setOpen, prnumber, setTrigger }) => {
  const handleCancelPr = async () => {
    const token = localStorage.getItem("jwttoken");
    const API = `${process.env.REACT_APP_SERVER_URL}/purchase-request-cancel/${prnumber}`;

    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    };

    try {
      const res = await fetch(API, requestOptions);

      if (res.ok) {
        toast.success("PR Cancel");
        setTrigger((prv) => !prv);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something goes wrong");
    }

    setOpen(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={() => setOpen(false)}>
      <div className="flex flex-col items-center">
        <p className=" text-xl font-semibold">
          Are you sure you want to cancel this PR?
        </p>
        <div className="flex gap-x-5 mt-5">
          <button
            onClick={handleCancelPr}
            className="bg-[#1e3a8a] px-5 py-1 rounded-md text-white"
          >
            Yes
          </button>
          <button onClick={() => setOpen(false)}>No</button>
        </div>
      </div>
    </Modal>
  );
};

export default CancelPrModal;
