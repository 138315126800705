import { createSlice } from '@reduxjs/toolkit';
import { fetchMaterials} from '../action'
const initialState = {
    materials: [],
    loading: false,
    error: null,
  };
  
  const materialsSlice = createSlice({
    name: 'materials',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchMaterials.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchMaterials.fulfilled, (state, action) => {
          state.loading = false;
          state.materials = action.payload;
          state.error = null;
        })
        .addCase(fetchMaterials.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        });
    },
  });
  export default materialsSlice.reducer;  