import React from "react";
import { FaUsers, FaEnvelope } from "react-icons/fa";

const Sidebar = ({ activeComponent, setActiveComponent }) => {
  return (
    <div className="w-full h-screen bg-blue-900 text-white flex flex-col">
      <div className="p-4">
        <h2 className="text-xl font-semibold mb-6">Admin Console</h2>
        <ul>
          <li className="mb-4">
            <button
              onClick={() => setActiveComponent("ManageUsers")}
              className={`flex items-center w-full p-2 text-left rounded transition duration-200 ${
                activeComponent === "ManageUsers"
                  ? "bg-[#f5f5f5] text-black"
                  : "hover:bg-gray-300 hover:text-black"
              }`}
            >
              <FaUsers className="mr-3" />
              Manage Users
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveComponent("EmailConfiguration")}
              className={`flex items-center w-full p-2 text-left rounded transition duration-300 ${
                activeComponent === "EmailConfiguration"
                  ? "bg-[#f5f5f5] text-black"
                  : "hover:bg-gray-300 hover:text-black"
              }`}
            >
              <FaEnvelope className="mr-3" />
              Email Configuration
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
