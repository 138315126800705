import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import LoginPage from "./pages/Login";
import Navbar from "./components/UI/Navbar";
import Index from "./pages";
import Dashboard from "./components/Dashboard/Dashboard";
import PRDashboard from "./components/Dashboard/pr/table/PRDashboard";
import SIDashBoard from "./components/Dashboard/si/table/SIDashboard";
import PurhcaseRequestForm from "./components/Dashboard/pr/form/PurhcaseRequestForm";
import SiteIntructionForm from "./components/Dashboard/si/form/SiteInstructionForm";
import Admin from "./components/Dashboard/admin/Admin";
import EditPurchaseRequestForm from "./components/Dashboard/pr/form/editPurchaseRequestForm";
import EditSiteInstructionForm from "./components/Dashboard/si/form/editSiform";
import PrFormReview from "./components/Dashboard/approver/review/prFormReview";
import Prtable from "./components/Dashboard/approver/tables/prtable";
import SiTable from "./components/Dashboard/approver/tables/sitable";
import SiFormReview from "./components/Dashboard/approver/review/siFormReview";
import AdminBeta from "./components/Dashboard/admin/AdminV1";
import ViewPrpage from "./components/Dashboard/pr/viewPrPage";
function App() {
  const loggedIn = useSelector((state) => state.auth.isLoggedIn);
  return (
    <div className="bg-sky-100 pb-10">
      <Router>
        {loggedIn ? (
          <div>
            <Navbar />
            <div className="mx-auto my-10 max-w-[90rem] px-4 sm:px-6 lg:px-8">
              <Routes>
                <Route path="/" element={<Index />} />

                <Route path="/dashboard/*" element={<Dashboard />}>
                  <Route path="pr-dashboard" element={<PRDashboard />} />
                  <Route path="si-dashboard" element={<SIDashBoard />} />
                  <Route path="new-pr-form" element={<PurhcaseRequestForm />} />
                  <Route path="edit-pr" element={<EditPurchaseRequestForm />} />
                  <Route path="edit-si" element={<EditSiteInstructionForm />} />

                  <Route
                    path="initiate-site-instruction"
                    element={<SiteIntructionForm />}
                  />
                  <Route path="pr-approvals" element={<Prtable />} />
                  <Route path="si-approvals" element={<SiTable />} />
                  <Route path="admin-console" element={<AdminBeta />} />
                  {/* <Route path="admin-beta" element={<AdminBeta />} /> */}
                  <Route path="review-pr" element={<PrFormReview />} />
                  <Route path="review-si" element={<SiFormReview />} />

                  {/* Read only page */}
                  <Route path="view-pr" element={<ViewPrpage />} />
                </Route>
              </Routes>
            </div>
          </div>
        ) : (
          <Routes>
            <Route path="/" element={<LoginPage />} />
          </Routes>
        )}
      </Router>
    </div>
  );
}

export default App;
