import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const ManagerOnlyInput = ({ approverData, prnumber }) => {
  const [showFields, setShowFields] = useState(false);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    prorderno: "",
    prorderdate: "",
  });

  const [error, setError] = useState(null);

  useEffect(() => {
    if (approverData?.length > 0) {
      const status = approverData.every(
        (approver) => approver.status === "Approved"
      );

      setShowFields(status);
    } else {
      setShowFields(false);
    }
  }, [approverData]);

  const handleClose = async () => {
    if (validate()) return;

    const data = {
      purchaseOrderNumber: formData.prorderno,
      purchaseOrderDate: formData.prorderdate,
    };

    const token = localStorage.getItem("jwttoken");
    const API = `${process.env.REACT_APP_SERVER_URL}/purchase-request-close/review/${prnumber}`;

    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
      body: JSON.stringify(data),
    };

    try {
      const res = await fetch(API, requestOptions);

      if (res.ok) {
        toast.success("PR Close");
        navigate("/dashboard/pr-dashboard");
      }
    } catch (err) {
      console.log(err);
      toast.error("Something goes wrong");
    }
  };

  const validate = () => {
    const validationerror = {};

    if (!formData.prorderdate) {
      validationerror.prorderdate = true;
    }

    if (!formData.prorderno) {
      validationerror.prorderno = true;
    }

    setError(validationerror);

    const hasErrors = Object.values(validationerror).some((field) => {
      if (Array.isArray(field)) {
        // If the field is an array (details array), check each detail item
        return field.some((detailItem) =>
          Object.values(detailItem).some((error) => error === true)
        );
      }
      // If the field is a single boolean value, directly check it
      return field === true;
    });

    return hasErrors;
  };

  return (
    <div className="mt-10">
      <p className="font-semibold">For Manager/Admin Only</p>
      <div className="border border-gray-300 flex flex-col gap-y-2 rounded-md w-full p-5 mt-1 ">
        <div className="flex gap-x-5 items-center">
          <label
            className=" text-sm w-36 font-medium text-gray-900"
            htmlFor={""}
          >
            Purchase Order No
          </label>
          <input
            value={formData.prorderno}
            onChange={(e) => {
              setFormData({
                ...formData,
                prorderno: e.target.value,
              });
            }}
            disabled={!showFields}
            className={`border-gray-400 ${
              error?.prorderno && "border-red-600"
            } border text-[14px] bg-gray-200 px-2 py-1 rounded w-32`}
          />
        </div>
        <div className="flex gap-x-5 items-center">
          <label
            className=" text-sm  w-36  font-medium text-gray-900"
            htmlFor={""}
          >
            Purchase Order Date
          </label>
          <input
            value={formData.prorderdate}
            onChange={(e) => {
              setFormData({
                ...formData,
                prorderdate: e.target.value,
              });
            }}
            type="date"
            disabled={!showFields}
            className={` ${
              error?.prorderdate && "border-red-600"
            } border-gray-400 border text-[14px] bg-gray-200 px-2 py-1 rounded w-32`}
          />
        </div>
        {showFields && (
          <button
            onClick={handleClose}
            className="bg-[#3bb75a] w-32 shadow-md rounded-md text-white px-5 py-2 mt-2 hover:bg-[#2b8040]"
            type="button"
          >
            Close
          </button>
        )}
      </div>
    </div>
  );
};

export default ManagerOnlyInput;
