// order.js

import { createSlice } from "@reduxjs/toolkit";

const purchaseCreateSlice = createSlice({
  name: "purchaseCreate",
  initialState: {
    loading: false,
    error: null,
    purchaseRequest: null,
  },
  reducers: {
    purchaseRequestStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    purchaseRequestSuccess: (state, action) => {
      state.loading = false;
      state.purchaseRequest = action.payload;
    },
    purchaseRequestFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { purchaseRequestStart, purchaseRequestSuccess, purchaseRequestFailure } =
purchaseCreateSlice.actions;

export default purchaseCreateSlice.reducer;
