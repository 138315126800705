import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import MyDataGrid from "../../../UI/MyDataGrid";

import { FaRegFilePdf } from "react-icons/fa6";
import { AiOutlineEye } from "react-icons/ai";
import Pagination from "../../../UI/Pagination";

import { BiEditAlt } from "react-icons/bi";
import ViewSi from "../ViewSi";
import useSwr from "swr";
import axiosInstance from "../../../utils/axiosInstance";
import Loader from "../../../UI/Loader";
import SIFilters from "./SIFilters";
import { toast } from "react-hot-toast";
import CancelSiModal from "../../../UI/CancelSiModal";
import ViewExport from "../../pr/viewExport";

const SIDashBoard = () => {
  const [siteInstructionsData, setSiteInstructionsData] = useState([]);
  const [openViewModel, setOpenViewModel] = useState(false);
  const [selectedPr, setSelectedPr] = useState("");
  const [trigger, setTrigger] = useState(false);
  const [isOpenCancel, setOpenCancel] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  const role = useSelector((state) => state.auth.role);

  const columns = [
    {
      field: "sino",
      headerName: "SI No.",
      width: 120,
      renderCell: (params) => {
        const siNo = params.value;
        return <p className=" font-medium">{siNo}</p>;
      },
    },
    {
      field: "siDate",
      headerName: "SI Date",
      width: 100,
      renderCell: (params) => {
        const prDateStr = params.value;

        if (!prDateStr) {
          return <p>N/A</p>;
        }

        const date = new Date(prDateStr);

        const val = `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()}`;

        return <p>{val}</p>;
      },
    },

    {
      field: "projectnumber",
      headerName: "Project No.",
      width: 100,
    },
    {
      field: "totalValueSiteInstruction",
      headerName: "SI Amount",
      width: 120,
      renderCell: (params) => {
        const totalValue = params.value.value;
        const totalValueCurrency = params.value.currency;

        return <p>{`${totalValue} ${totalValueCurrency}`}</p>;
      },
    },
    {
      field: "timeExtension",
      headerName: "Time Ext",
      width: 120,
      renderCell: (params) => {
        const timeExt = params.value.value;
        const timeExtUnit = params.value.time;

        return <p>{`${timeExt} ${timeExtUnit}`}</p>;
      },
    },
    {
      field: "contractScope",
      headerName: "In-Scope?",
      width: 100,
      renderCell: (params) => {
        const status = params.value;
        return <p className="">{status}</p>;
      },
    },

    {
      field: "sistatus",
      headerName: "SI Status",
      width: 150,
      renderCell: (params) => {
        const status = params.value;

        let bgColor = "bg-green-500";
        let textColor = "text-white";
        if (status === "Draft") {
          bgColor = "bg-orange-300";
          textColor = "text-white";
        }

        if (status === "Pending") {
          bgColor = "bg-red-400";
          textColor = "text-white";
        }
        if (status === "Rejected") {
          bgColor = "bg-red-600";
          textColor = "text-white";
        }
        if (status === "Closed") {
          bgColor = "bg-gray-500";
          textColor = "text-white";
        }
        if (status === "Cancelled") {
          bgColor = "bg-gray-600";
          textColor = "text-white";
        }
        return (
          <p
            className={`${bgColor}  ${textColor} px-2 text-[12px] py-1 rounded-md `}
          >
            {status}
          </p>
        );
      },
    },
    {
      field: "prnumber",
      headerName: "Linked PR No.",
      width: 120,
      renderCell: (params) => {
        const prId = params.value;
        return <p className="font-medium">{prId}</p>;
      },
    },
    {
      field: "id",
      headerName: "Actions Section:",
      width: 260,
      renderCell: (params) => {
        const ids = params.row.prnumber;
        const status = params.row.sistatus;

        let color;
        let showLink = false;
        let disableDownload = true;
        let showCancelBtn = false;

        if (status === "Draft" || status === "Rejected") {
          color = "text-[#1e3a8a]";
          showLink = true;
        }
        if (status === "Pending") {
          color = "text-red-600";
        }
        if (status === "Closed" || status === "Cancelled") {
          disableDownload = false;
        }

        if (
          status != "Cancelled" &&
          status != "Approved" &&
          role === "admin" &&
          status != "Rejected"
        ) {
          showCancelBtn = true;
        } else {
          showCancelBtn = false;
        }

        return (
          <div className="flex gap-x-4">
            <button
              onClick={() => {
                setSelectedPr(ids);
                setOpenViewModel(true);
              }}
            >
              <AiOutlineEye className="text-[#1e3a8a] text-2xl" />
            </button>

            {status === "Draft" ? (
              <Link to={`/dashboard/edit-si?prnumber=${ids}`}>
                <button className="">
                  <BiEditAlt className="text-[#3bb7b6] text-2xl" />
                </button>
              </Link>
            ) : (
              <button className="text-gray-500 cursor-not-allowed" disabled>
                <BiEditAlt className="text-[#afafaf] text-2xl" />
              </button>
            )}

            {status === "Approved" ? (
              <button
                onClick={() => downloadPdf(ids)}
                className="text-red-600 text-2xl disabled:text-gray-500"
              >
                <FaRegFilePdf className="text-red-600 text-2xl" />
              </button>
            ) : (
              <button
                onClick={() => downloadPdf(ids)}
                className="text-red-600 text-2xl disabled:text-gray-500"
                disabled
              >
                <FaRegFilePdf className="text-[#afafaf] text-2xl" />
              </button>
            )}

            {role === "admin" && (
              <button
                disabled={!showCancelBtn}
                onClick={() => {
                  setOpenCancel(true);
                  setSelectedPr(ids);
                }}
                className="text-[14px] text-blue-600 underline"
              >
                Cancel
              </button>
            )}
          </div>
        );
      },
    },
  ];

  const initialSearch = {
    showRecords: "30 Days",
    status: "All",
    inScope: "All",
    search: "",
    from: "",
    to: "",
  };

  const [filter, setFilter] = useState(initialSearch);
  const [inputFilter, setInputFilter] = useState(initialSearch);
  const [pageNo, setPageNo] = useState(0);

  async function fetchSI() {
    try {
      const response = await axiosInstance.get(
        `/site-instructions?pageNo=${pageNo}&showLastDays=${filter.showRecords}&seachText=${filter.search}&status=${filter.status}&inScope=${filter.inScope}&fromDate=${filter.fromDate}&toDate=${filter.to}`
      );
      // console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  const { data: siData, isLoading } = useSwr(
    ["si", pageNo, filter.search, filter.to, filter.from, trigger],
    fetchSI
  );

  useEffect(() => {
    if (siData?.data?.length > 0) {
      const data = siData?.data?.map((row) => ({
        ...row,
        id: row._id,
      }));

      setSiteInstructionsData(data);
    }
  }, [siData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let filters = { ...inputFilter };
    filters[name] = value;
    setInputFilter(filters);
  };

  useEffect(() => {
    if (isLoading) return;

    let filteredData = siData?.data;

    if (filteredData?.length === 0) return;

    if (inputFilter.status !== "All") {
      filteredData = filteredData?.filter(
        (res) => res.sistatus === inputFilter.status
      );
    }

    if (inputFilter.inScope !== "All") {
      filteredData = filteredData?.filter((res) => {
        if (inputFilter.inScope === "Yes") {
          return res.contractScope === "In Scope";
        } else if (inputFilter.inScope === "No") {
          return res.contractScope === "Out of Scope";
        }
        return true;
      });
    }

    if (inputFilter.showRecords) {
      const today = new Date();
      const dateAgo = new Date();
      dateAgo.setDate(dateAgo.getDate() - 30);
      filteredData = filteredData.filter((item) => {
        const createdAtDate = new Date(item.createdAt);
        return createdAtDate >= dateAgo && createdAtDate <= today;
      });
    }

    const data = filteredData.map((row) => ({
      ...row,
      id: row.prnumber + "&" + row.sistatus,
    }));

    setSiteInstructionsData(data);
  }, [
    inputFilter.showRecords,
    inputFilter.status,
    inputFilter.inScope,
    pageNo,
  ]);

  const handleSearch = () => {
    setFilter(inputFilter);
  };

  function handleClear() {
    setInputFilter(initialSearch);
    setFilter(initialSearch);
  }
  const handleExport = () => {
    if (siteInstructionsData.length > 0) {
      setExportModal(true);
    } else {
      toast.error("SI Not found");
    }
  };
  async function downloadPdf(prnumber) {
    const API = `${process.env.REACT_APP_SERVER_URL}/si-pdf?prnumber=${prnumber}`;

    try {
      const response = await fetch(API, {
        method: "GET",

        headers: {
          Accept: "application/pdf",
        },
      });

      if (!response.ok) {
        throw new Error("Error: " + response.status);
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = `${prnumber}-SI`;
      link.click();

      URL.revokeObjectURL(url);

      toast.success("Pdf Download Started");
    } catch (error) {
      toast.error("Something went wrong");
    }
  }

  return (
    <div className=" w-full">
      <div className="min-h-screen h-100% bg-white py-3">
        <h1 className=" font-bold text-center text-2xl ">
          Site Instruction Tracker
        </h1>
        <main className="flex justify-center py-8 px-4 sm:px-8">
          <div className=" w-full">
            <div className="w-full">
              <SIFilters
                isShowClearBtn={
                  inputFilter?.to
                    ? true
                    : inputFilter?.from
                    ? true
                    : inputFilter?.search
                    ? true
                    : false
                }
                handleClear={handleClear}
                handleInputChange={handleInputChange}
                filter={inputFilter}
                handleExport={handleExport}
                handleSearch={handleSearch}
              />
              {isLoading ? (
                <Loader />
              ) : (
                <MyDataGrid
                  bgColor={"#3bb7b6"}
                  rows={siteInstructionsData}
                  columns={columns}
                />
              )}
              <div className="flex justify-center">
                <Pagination setPageNo={setPageNo} totalItem={siData?.total} />
              </div>
            </div>
          </div>
        </main>
      </div>
      {openViewModel && selectedPr && (
        <ViewSi
          prNumber={selectedPr}
          setOpen={setOpenViewModel}
          isOpen={openViewModel}
        />
      )}
      {exportModal && (
        <ViewExport isOpen={exportModal} setOpen={setExportModal} type={"SI"} />
      )}
      {isOpenCancel && selectedPr && (
        <CancelSiModal
          prnumber={selectedPr}
          isOpen={isOpenCancel}
          setOpen={setOpenCancel}
          setTrigger={setTrigger}
        />
      )}
    </div>
  );
};

export default SIDashBoard;
