import { createSlice } from "@reduxjs/toolkit";
import { getSiById } from "../action.js";

const singleSiRequestSlice = createSlice({
  name: "singleSi",
  initialState: {
    siRequests: [],
    isLoading: false,
    isError: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSiById.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(getSiById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.siRequests = action.payload;
      })
      .addCase(getSiById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload;
      });
  },
});

export default singleSiRequestSlice.reducer;
