import React, { useEffect, useState } from "react";
import AppMultipleEmailInput from "../../UI/Input/AppMultipleEmailInput";
import {
  createOrUpdateCommercialEmail,
  createOrUpdateContractorEmail,
  getCommercialEmail,
  getContractorEmail,
} from "../../../redux/action";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
const EmailConfiguration = () => {
  const [toEmails, setToEmails] = useState("");
  const [ccEmails, setCcEmails] = useState("");
  const [contractorsCcEmails, setContractorsCcEmails] = useState("");
  const [toEmailError, setToEmailError] = useState(false);
  const [ccEmailError, setCcEmailError] = useState(false);
  const [contractorsCcEmailsError, setcontractorsCcEmailError] =
    useState(false);
  const dispatch = useDispatch();
  const loadEmails = async () => {
    try {
      const emailConfig = await dispatch(getCommercialEmail());
      const ContractorsEmail = await dispatch(getContractorEmail());
      console.log("Fetched email config:", emailConfig);

      if (emailConfig) {
        setToEmails(emailConfig.TO || "");
        setCcEmails(emailConfig.CC || "");
      }
      if (ContractorsEmail) {
        setContractorsCcEmails(ContractorsEmail.CC || "");
      }
    } catch (error) {
      console.error("Failed to load email config:", error);
    }
  };
  useEffect(() => {
    loadEmails();
  }, []);
  const handleToEmailsChange = (e, isValid) => {
    setToEmails(e.target.value);
    setToEmailError(!isValid);
  };

  const handleCcEmailsChange = (e, isValid) => {
    setCcEmails(e.target.value);
    setCcEmailError(!isValid);
  };
  const handleContractorsCcEmailsChange = (e, isValid) => {
    setContractorsCcEmails(e.target.value);
    setcontractorsCcEmailError(!isValid);
  };
  const handleCommercialSubmit = async (e) => {
    e.preventDefault();
    if (toEmailError || ccEmailError) {
      toast.error("Emails are invalid");
      return;
    }

    toast.promise(
      dispatch(
        createOrUpdateCommercialEmail({ TO: toEmails, CC: ccEmails })
      ).then(() => {
        loadEmails();
      }),
      {
        loading: "Updating email configuration...",
        success: "Emails updated successfully",
        error: "Failed to update email",
      }
    );
  };
  const handleContractorSubmit = async (e) => {
    e.preventDefault();
    if (contractorsCcEmailsError) {
      toast.error("Emails are invalid");
      return;
    }

    toast.promise(
      dispatch(createOrUpdateContractorEmail({ CC: contractorsCcEmails })).then(
        () => {
          loadEmails();
        }
      ),
      {
        loading: "Updating email configuration...",
        success: "Emails updated successfully",
        error: "Failed to update email",
      }
    );
  };
  return (
    <div className="h-full bg-white p-4">
      <main className="py-2 px-4 flex flex-col gap-4">
        <h1 className="text-lg font-semibold text-gray-700">
          {" "}
          Email Recipients Configuration
        </h1>
        <div className="border border-gray-200 flex flex-col gap-4 py-4 px-8 ">
          <h1 className="text-md font-semibold text-blue-900">
            Commercial Table
          </h1>
          <div>
            <h1 className="text-sm">To</h1>
            <AppMultipleEmailInput
              value={toEmails}
              isError={toEmailError}
              onChange={handleToEmailsChange}
              name="toEmails"
            />
          </div>
          <div>
            <h1 className="text-sm">Cc</h1>
            <AppMultipleEmailInput
              value={ccEmails}
              isError={ccEmailError}
              onChange={handleCcEmailsChange}
              name="ccEmails"
            />
          </div>
          <div className="flex ">
            <button
              className="bg-[#194099] shadow-md hover:shadow-xl rounded-md text-white px-5 py-2 mt-3"
              type="submit"
              onClick={handleCommercialSubmit}
            >
              Save
            </button>
          </div>
        </div>
        <div className="border border-gray-200 flex flex-col gap-4 py-4 px-8 ">
          <h1 className="text-md font-semibold text-blue-900">
            Contractors & HSE Table
          </h1>

          <div>
            <h1 className="text-sm">Cc</h1>
            <AppMultipleEmailInput
              value={contractorsCcEmails}
              isError={contractorsCcEmailsError}
              onChange={handleContractorsCcEmailsChange}
              name="ccEmails"
            />
          </div>
          <div className="flex ">
            <button
              className="bg-[#194099] shadow-md hover:shadow-xl rounded-md text-white px-5 py-2 mt-3"
              type="submit"
              onClick={handleContractorSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default EmailConfiguration;
