import React, { useState, useEffect } from "react";
import AppTextInput from "../../../UI/Input/AppTextInput";
import AppEmailInput from "../../../UI/Input/AppEmailInput";

const ExternalContractor = ({
  externalContractor,
  setExternalContractor,
  isSupplierSelected,
  setIsSupplierSelected,
  errors,
}) => {
  const [modal, setModal] = useState(false);
  useEffect(() => {
    if (externalContractor) {
      setIsSupplierSelected(
        externalContractor.externalContractor ? "yes" : "no"
      );
      setModal(externalContractor.externalContractor);
    }
  }, [externalContractor, setIsSupplierSelected]);
  // useEffect(() => {
  //   setExternalContractor((prevFormData) => ({
  //     ...prevFormData,
  //     externalContractor: false,
  //   }));
  // }, [setExternalContractor]);

  const handleRadioChange = (value) => {
    setIsSupplierSelected(value);
    setModal(value === "yes");
    setExternalContractor((prevFormData) => ({
      ...prevFormData,
      externalContractor: value === "yes",
      ContractorsName: value === "yes" ? prevFormData.ContractorsName : "",
      ContractorsEmail: value === "yes" ? prevFormData.ContractorsEmail : "",
      SubContractorsEmail:
        value === "yes" ? prevFormData.SubContractorsEmail : "",
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setExternalContractor((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <div className="py-2 px-8 border border-gray-300 rounded-md mt-8 mb-4">
      <label
        className="block text-[14px] uppercase font-medium text-gray-900 mt-4"
        htmlFor="additionalComments"
      >
        Is there a new Supplier/Contractor for this Purchase Request?
      </label>
      <div className="mt-2">
        <label className="inline-flex items-center mr-6">
          <input
            type="radio"
            name="supplier"
            value="yes"
            checked={isSupplierSelected === "yes"}
            onChange={() => handleRadioChange("yes")}
            className="form-radio"
          />
          <span className="ml-2">Yes</span>
        </label>
        <label className="inline-flex items-center">
          <input
            type="radio"
            name="supplier"
            value="no"
            checked={isSupplierSelected === "no"}
            onChange={() => handleRadioChange("no")}
            className="form-radio"
          />
          <span className="ml-2">No</span>
        </label>
      </div>
      {modal && (
        <div className="my-4 flex flex-col gap-4">
          <AppTextInput
            value={externalContractor?.ContractorsName}
            isError={errors.ContractorsName}
            onChange={handleInputChange}
            name={"ContractorsName"}
            label={"Contractors Name"}
          />
          <AppEmailInput
            value={externalContractor?.ContractorsEmail}
            isError={errors.ContractorsEmail}
            onChange={handleInputChange}
            name={"ContractorsEmail"}
            label={"Contractors Email"}
          />
          <AppEmailInput
            value={externalContractor?.SubContractorsEmail}
            isError={errors.SubContractorsEmail}
            onChange={handleInputChange}
            name={"SubContractorsEmail"}
            label={"Sub-Contractors Email"}
          />
        </div>
      )}
    </div>
  );
};

export default ExternalContractor;
