import React from "react";
import Modal from "../Modal";

const AskDeleteUserModal = ({ isOpen, setOpen, handleDeleteUser }) => {
  return (
    <Modal isOpen={isOpen} onClose={() => setOpen(false)}>
      <div className="flex flex-col items-center">
        <h2 className="font-semibold text-xl">Are You Sure ?</h2>

        <div className="flex gap-x-5 mt-5">
          <button
            onClick={handleDeleteUser}
            className="bg-[#1e3a8a] px-5 py-1 rounded-md text-white"
          >
            Yes
          </button>
          <button onClick={() => setOpen(false)}>No</button>
        </div>
      </div>
    </Modal>
  );
};

export default AskDeleteUserModal;
