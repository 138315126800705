import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getPrById, fetchFiles } from "../../../redux/action";

// import ManagerOnlyInput from "../../pr/form/ManagerOnlyInput";
// import ApproverActionSection from "./approverActionSection";
import { FaDownload } from "react-icons/fa";
import FormDynamicValues from "./form/FormDynamicValues";
import AppTextInput from "../../UI/Input/AppTextInput";
import DetailsFormTable from "./form/DetailsFormTable";
import AppDateInput from "../../UI/Input/AppDateInput";
// import ApproverActionSection from "../approver/review/approverActionSection";

const initialDetail = {
  quantity: "",
  description: "",
  unitprice: "",
  currency: "",
  amount: "",
  wbs: "",
  wbsname: "",
};

const ViewPrpage = () => {
  const [searchParams] = useSearchParams();
  const [attachments, setAttachments] = useState([]);
  const dispatch = useDispatch();

  const prNumber = searchParams.get("prnumber");

  const purchaseRequest = useSelector(
    (state) => state.signlePr.purchaseRequests
  );
  const submittedby = purchaseRequest?.submittedby;

  const [formData, setFormData] = useState({
    prnumber: "",
    prdate: "4",
    submittedby: submittedby,
    status: "",
    projectnumber: "",
    inScope: "",
    prtitle: "",
    vendor: "",
    materialgrp: "",
    quoteno: "",
    leadtime: "",
    requestedby: "",
    paymentterms: "",
    duedate: "",
    additionalcomment: "",
    details: [initialDetail],
    approvers: [],
    externalContractor: {},
  });

  const [details, setDetails] = useState([initialDetail]);

  useEffect(() => {
    dispatch(getPrById(prNumber));
    dispatch(fetchFiles(prNumber))
      .then((res) => {
        setAttachments(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [prNumber, dispatch]);

  useEffect(() => {
    if (purchaseRequest) {
      console.log(purchaseRequest);
      setFormData({
        ...purchaseRequest,
        submittedby: submittedby,
        status: purchaseRequest?.prstatus,
      });

      setDetails([initialDetail]);
    }
  }, [purchaseRequest]);

  function handleSubmit(e) {
    e.preventDefault();
  }

  return (
    <section className=" shadow-lg p-5  bg-white">
      <h1 className="text-2xl font-bold leading-6 text-slate-700 mb-8 text-center">
        View Visy Glass Purchase Requisition Request Form
      </h1>

      <div className=" flex gap-x-5 justify-between items-end">
        <FormDynamicValues
          disable={true}
          prnumber={prNumber}
          prdate={formData.prdate}
          submittedby={formData.submittedby}
          status={formData.status}
        />
      </div>

      <form onSubmit={handleSubmit} className="grid mt-5 grid-cols-12 gap-x-5">
        <div className="col-span-3   flex flex-col gap-y-3">
          <AppTextInput
            disable
            value={formData.prtitle}
            name={"prtitle"}
            label={"PR TITLE"}
          />
          <AppTextInput
            disable
            value={formData.projectnumber}
            name={"projectnumber"}
            label={"PROJECT NO."}
          />
          <AppTextInput
            disable
            value={formData.vendor}
            name={"vendor"}
            label={"VENDOR"}
          />
          <div>
            <label
              className="block text-sm font-medium text-gray-900"
              htmlFor={"Scope"}
            >
              WITHIN SCOPE?
            </label>
            <select
              name="scope"
              disabled
              value={formData.inScope}
              className="mt-1 bg-gray-200  rounded-md border-gray-400 border px-2 py-2  w-full "
            >
              <option className="">Select</option>
              <option>In Scope</option>
              <option>Out of Scope</option>
            </select>
          </div>

          <AppTextInput
            disable
            value={formData.quoteno}
            name={"quoteno"}
            label={"QUOTE NO"}
          />
          <AppTextInput
            disable
            value={formData.leadtime}
            name={"leadtime"}
            label={"LEAD TIME"}
          />
          <AppTextInput
            disable
            value={formData.requestedby}
            name={"requestedby"}
            label={"REQUESTED BY"}
          />
          <AppTextInput
            disable
            value={formData.paymentterms}
            name={"paymentterms"}
            label={"PAYMENT TERMS"}
          />
          <AppDateInput
            disable
            value={formData.duedate}
            name={"dueDate"}
            label={"DUE DATE"}
          />
        </div>
        <div className="col-span-9 mt-5 ">
          {formData?.details?.length > 0 && (
            <DetailsFormTable
              formData={formData}
              defaultData={formData.details}
              setDetails={setDetails}
              setFormData={setFormData}
              details={details}
              disable={true}
            />
          )}
          <div className="col-span-12">
            {formData?.externalContractor?.externalContractor && (
              <div className="mt-5 text-[14px] flex flex-col gap-y-2 text-gray-600">
                <h3 className="font-semibold text-lg text-[#1e3a8a] mb-2">
                  External Contractors
                </h3>

                <p>
                  Contractors Name:{" "}
                  {formData.externalContractor?.ContractorsName}
                </p>
                <p>
                  Contractors Email:{" "}
                  {formData.externalContractor?.ContractorsEmail}
                </p>
                <p>
                  Sub-Contractors Email:{" "}
                  {formData.externalContractor?.SubContractorsEmail}
                </p>
              </div>
            )}
          </div>
          {/* Attachments  */}
          <div className="mt-5 border border-gray-200 p-4">
            <h3 className="block text-sm font-medium text-gray-900 mb-4">
              ATTACHMENTS
            </h3>
            {attachments.length > 0 ? (
              <div className="overflow-x-auto ">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        File Name
                      </th>
                      <th className="px-6 py-3 bg-gray-50"></th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 ">
                    {attachments.map((attachment, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {attachment.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <a
                            href={attachment.fileUrl}
                            target="_blank"
                            download
                            rel="noopener noreferrer"
                            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-[#194099] hover:bg-[#305abc] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            <FaDownload className="mr-2 h-4 w-4" />
                            Download
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>No attachments available.</p>
            )}
          </div>
          <div className="col-span-5 mt-6 border border-gray-200 px-4 py-2">
            <p className="font-semibold  text-md mb-5">Approvers</p>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Email
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Comments
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {formData?.approvers?.map((item, i) => (
                    <tr key={i}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {item.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {item.email}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-600 max-w-xs break-words">
                        {item.comments}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <span
                          className={`inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium ${
                            item?.status === "Approved"
                              ? "bg-green-100 text-green-800"
                              : item?.status === "Pending"
                              ? "bg-orange-100 text-orange-800"
                              : item?.status === "Rejected"
                              ? "bg-gray-100 text-gray-800"
                              : ""
                          }`}
                        >
                          {item?.status}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Attachments end  */}
          {/* <ApproverActionSection
            prnumber={prNumber}
            approverData={formData.approvers}
          /> */}
          {/*
          <ManagerOnlyInput
            prnumber={prNumber}
            approverData={formData.approvers}
          /> */}
        </div>
      </form>
    </section>
  );
};

export default ViewPrpage;
